.custom-header{
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 101;
    padding: 0 30px;
    height: 105px;
    backdrop-filter: blur(15px);
}

.custom-header.active{
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: var(--darkblue-200);
    -webkit-box-shadow: 0 0 40px 0px var(--electricblue-200);
       -moz-box-shadow: 0 0 40px 0px var(--electricblue-200);
            box-shadow: 0 0 40px 0px var(--electricblue-200);
}

.ch-inner{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    min-height: 70px;
    padding: 20px 0;
}

.ch-inner .ch-right,
.ch-inner .ch-left{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 160px;
    /* width: 194px; */
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}

.ch-inner .ch-right{
    width: auto;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
}

.ch-inner .ch-left{
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.ch-inner .ch-right{
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}


/* --- Logo Css Start --- */
.ch-logo{
    cursor: pointer;
}

.ch-logo img{
    width: 160px;
    display: block;
    position: relative;
    top: -2px;
}

.ch-logo img:nth-child(2){
    display: none;
}

.ch-logo img.lighticon{
    -webkit-transform: scale(1.3);
       -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
         -o-transform: scale(1.3);
            transform: scale(1.3);
}
/* --- Logo Css End --- */

/* --- Menu Links Start --- */
.ch-menu-bx{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 15px;
    width: 100%;
}

.ch-menu-bx .cmb-item{
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: var(--celeste-600);
    font-size: 14px;
    white-space: nowrap;
    margin:0 2px;
    padding: 8px 14px 8px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    font-weight: 500;
    overflow: hidden;
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
}

.ch-menu-bx .cmb-item svg{
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.ch-menu-bx .cmb-item .cmb-item-line{
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background-image: -webkit-gradient(linear, right top, left top, from(var(--darkblue)), color-stop(var(--electricblue)), to(var(--darkblue)));
    background-image: -webkit-linear-gradient(right, var(--darkblue), var(--electricblue), var(--darkblue));
    background-image: -moz-linear-gradient(right, var(--darkblue), var(--electricblue), var(--darkblue));
    background-image: -o-linear-gradient(right, var(--darkblue), var(--electricblue), var(--darkblue));
    background-image: linear-gradient(-90deg, var(--darkblue), var(--electricblue), var(--darkblue));
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.ch-menu-bx .cmb-item .cmb-item-line::before,
.ch-menu-bx .cmb-item .cmb-item-line::after{
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 60%;
    z-index: 1;
}

.ch-menu-bx .cmb-item .cmb-item-line::after{
    left: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(left, var(--darkblue), transparent);
    background-image: -moz-linear-gradient(left, var(--darkblue), transparent);
    background-image: -o-linear-gradient(left, var(--darkblue), transparent);
    background-image: linear-gradient(90deg, var(--darkblue), transparent);
}

.ch-menu-bx .cmb-item .cmb-item-line::before{
    right: 0;
    background-image: -webkit-gradient(linear, right top, left top, from(var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(right, var(--darkblue), transparent);
    background-image: -moz-linear-gradient(right, var(--darkblue), transparent);
    background-image: -o-linear-gradient(right, var(--darkblue), transparent);
    background-image: linear-gradient(-90deg, var(--darkblue), transparent);
}

.cmb-item.cmb-item-highlight{
    margin-left: -80px !important;
    background: -webkit-linear-gradient(182.25deg, var(--azure), var(--electricblue));
    background: -moz-linear-gradient(182.25deg, var(--azure), var(--electricblue));
    background: -o-linear-gradient(182.25deg, var(--azure), var(--electricblue));
    background: linear-gradient(267.75deg, var(--azure), var(--electricblue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* position: absolute;
    right: -2px; */
    font-weight: 600;
    font-size: 15px;
    -webkit-background-size: 200% 200%;
       -moz-background-size: 200%;
         -o-background-size: 200%;
            background-size: 200%;
    background-position: 0% 50%;
	-webkit-animation: cih_gradient 1.5s infinite forwards;
	   -moz-animation: cih_gradient 1.5s infinite forwards;
	     -o-animation: cih_gradient 1.5s infinite forwards;
	        animation: cih_gradient 1.5s infinite forwards;
}

.ch-menu-bx .cmb-item:hover{
    color: var(--celeste);
    /* cursor: pointer;
    background-color: var(--celeste-100); */
}

@-webkit-keyframes cih_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes cih_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-o-keyframes cih_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes cih_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* .ch-menu-bx .cmb-item:hover .cmb-item-line{
    opacity: 1;
} */

.cmb-drop-item.active .cmb-item,
.ch-menu-bx .cmb-item.active{
    color: var(--celeste);
    cursor: pointer;
    background-color: var(--celeste-100);
}

.cmb-drop-item.active .cmb-item .cmb-item-line,
.ch-menu-bx .cmb-item.active .cmb-item-line{
    opacity: 1;
}
/* --- Menu Links End --- */

/* --- --- */
.cmb-drop-item{
    position: relative;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
}

.cmb-drop-item .cmb-drop-bx{
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 0;
    padding-top: 50px;
    visibility: hidden;
}

.cmb-drop-item.active .cmb-drop-bx{
    visibility: visible;
}

.cmb-drop-item .cmb-drop-bx .cmb-drop-inner{
    padding: 10px;
    -webkit-border-radius: 12px;
       -moz-border-radius: 12px;
            border-radius: 12px;
    background-clip: padding-box;
    background-color: var(--darkblue);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(5px);
       -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
         -o-transform: translateY(5px);
            transform: translateY(5px);
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    -webkit-box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
       -moz-box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
            box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
}

.cmb-drop-item.active .cmb-drop-bx .cmb-drop-inner{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.cdi-main{
    width: 150px;
}

.cdi-main.cdi-tools-width{
    width: 205px;
}

.cdi-main .cdi-main-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 6px 6px 6px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 16px;
    margin-top: 8px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.cdi-main .cdi-main-item img{
    width: 20px;
    height: 20px;
    opacity: 0.4;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.cdi-main .cdi-main-item:first-child{
    margin-top: 0;
}

.cdi-main .cdi-main-item:hover img{
    opacity: 1;
}

.cdi-main .cdi-main-item:hover{
    color: var(--celeste);
    cursor: pointer;
}

.cdi-main .cdi-main-item.active{
    color: var(--celeste);
    cursor: pointer;
}
/* --- --- */

/* --- Aside Menu Icon Start --- */
.menu-icon-bx {
    width: 30px;
    height: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 30px;
            flex: 0 0 30px;
    display: none;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px;
    cursor: pointer;
    margin-left: 15px;
}

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 3px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    opacity: 0.8;
    background-color: var(--celeste);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.menu-icon-bx span:nth-child(3) {
    width: 60%;
    margin-right: auto;
}

.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
}

.menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
}
/* --- Aside Menu Icon End --- */


/* --- Signinup Btn Bx Start --- */
.signinup-btn-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

.signinup-btn-bx .sip-btn{
    position: relative;
    color: var(--electricblue);
    border: 1px solid transparent;
    padding: 10px 20px 10px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
}

.signinup-btn-bx .sip-btn span{
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signinup-btn-bx .sip-btn.register-sip-btn{
    border-color: var(--electricblue-600);
}


.signinup-btn-bx .sip-btn.sip-account-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.signinup-btn-bx .sip-btn img{
    width: 30px;
    height: 30px;
    position: relative;
    top: 0.5px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    margin-right: 5px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
}


.signinup-btn-bx .sip-btn.register-sip-btn .sbdata,
.signinup-btn-bx .sip-btn.register-sip-btn::before{
    content: attr(sbdata);
    position: absolute;
    inset: 0px;
    color: var(--celeste);
    background: var(--electricblue-500);
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transform: translateX(-100%);
       -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
         -o-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signinup-btn-bx .sip-btn.register-sip-btn::before{
    display: none;
}


/* --- Signinup Btn Bx End --- */









/* --- Custom Header White --- */
.lighttheme:not(.active-dark) .custom-header{
    /* background-color: var(--white); */
    background-color: #fafafa;
}

.lighttheme:not(.active-dark) .custom-header.active{
    /* background-color: var(--white); */
    background-color: rgba(250, 250, 250, 0.8);
}

.lighttheme:not(.active-dark) .custom-header .ch-menu-bx .cmb-item{
    font-weight: 600;
    color: var(--darkblue-600);
}

.lighttheme:not(.active-dark) .custom-header .ch-menu-bx .cmb-item:hover{
    color: var(--darkblue);
}

.lighttheme:not(.active-dark) .custom-header .ch-menu-bx .cmb-item.active{
    background-color: var(--darkblue-100);
}

.lighttheme:not(.active-dark) .custom-header .ch-menu-bx .cmb-item .cmb-item-line{
    background-image: -webkit-gradient(linear, right top, left top, from(var(--white)), color-stop(var(--electricblue)), to(var(--white)));
    background-image: -webkit-linear-gradient(right, var(--white), var(--electricblue), var(--white));
    background-image: -moz-linear-gradient(right, var(--white), var(--electricblue), var(--white));
    background-image: -o-linear-gradient(right, var(--white), var(--electricblue), var(--white));
    background-image: linear-gradient(-90deg, var(--white), var(--electricblue), var(--white));
}

.lighttheme:not(.active-dark) .custom-header .ch-menu-bx .cmb-item .cmb-item-line::after{
    background-image: -webkit-gradient(linear, left top, right top, from(var(--white)), to(transparent));
    background-image: -webkit-linear-gradient(left, var(--white), transparent);
    background-image: -moz-linear-gradient(left, var(--white), transparent);
    background-image: -o-linear-gradient(left, var(--white), transparent);
    background-image: linear-gradient(90deg, var(--white), transparent);
}

.lighttheme:not(.active-dark) .custom-header .ch-menu-bx .cmb-item .cmb-item-line::before{
    background-image: -webkit-gradient(linear, right top, left top, from(var(--white)), to(transparent));
    background-image: -webkit-linear-gradient(right, var(--white), transparent);
    background-image: -moz-linear-gradient(right, var(--white), transparent);
    background-image: -o-linear-gradient(right, var(--white), transparent);
    background-image: linear-gradient(-90deg, var(--white), transparent);
}

.lighttheme:not(.active-dark) .custom-header .signinup-btn-bx .sip-btn{
    color: var(--azure);
}

.lighttheme:not(.active-dark) .custom-header .signinup-btn-bx .sip-btn.register-sip-btn{
    color:var(--darkblue);
    border-color: var(--darkblue-600);
}

.lighttheme:not(.active-dark) .custom-header .signinup-btn-bx .sip-btn.register-sip-btn::before{
    color:var(--white);
    border-color: var(--darkblue);
    background-color: var(--darkblue);
}

.lighttheme:not(.active-dark) .custom-header .signinup-btn-bx .sip-btn.register-sip-btn .sbdata{
    background-color: var(--darkblue);
}

.lighttheme:not(.active-dark) .menu-icon-bx span{
    background-color: var(--darkblue);
}

.lighttheme:not(.active-dark) .cmb-drop-item .cmb-drop-bx .cmb-drop-inner{
    background-color: var(--white);
    -webkit-box-shadow: 0 0 50px 10px rgb(183 183 183 / 30%);
       -moz-box-shadow: 0 0 50px 10px rgb(183 183 183 / 30%);
            box-shadow: 0 0 50px 10px rgb(183 183 183 / 30%);
}

.lighttheme:not(.active-dark) .cdi-main .cdi-main-item{
    color: var(--darkblue-600);
    font-weight: 600;
}

.lighttheme:not(.active-dark) .cdi-main .cdi-main-item:hover{
    color: var(--darkblue);
}

.lighttheme:not(.active-dark) .cmb-item.cmb-item-highlight{
    font-size: 14px;
}

@media screen and (max-width:1650px){
    html[lang="ml"] .cmb-item-highlight{
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .signinup-btn-bx .sip-btn:hover{
        color: var(--darkblue);
        border-color: var(--electricblue-100);
        background-color: var(--electricblue);
        cursor: pointer;
    }
    
    .signinup-btn-bx .sip-btn.register-sip-btn:hover{
        color: var(--electricblue);
        background-color: transparent;
    }
    
    .signinup-btn-bx .sip-btn.register-sip-btn:hover span{
        -webkit-transform: translateX(-webkit-calc(100% + 20px));
           -moz-transform: translateX(-moz-calc(100% + 20px));
            -ms-transform: translateX(calc(100% + 20px));
             -o-transform: translateX(calc(100% + 20px));
                transform: translateX(calc(100% + 20px));
    }
    
    .signinup-btn-bx .sip-btn.register-sip-btn:hover .sbdata,
    .signinup-btn-bx .sip-btn.register-sip-btn:hover::before{
        -webkit-transform: translateX(0);
           -moz-transform: translateX(0);
            -ms-transform: translateX(0);
             -o-transform: translateX(0);
                transform: translateX(0);
    }

    .lighttheme:not(.active-dark) .custom-header .signinup-btn-bx .sip-btn:not(.register-sip-btn):hover{
        color: var(--white);
        background-color: var(--azure);
    }
}


/* --- @media Css Start --- */
@media screen and (max-width:1300px) {
    .ch-menu-bx .cmb-item:not(.cmb-item-highlight),
    .ch-menu-bx .cmb-drop-item{
        display: none;
    }

    .menu-icon-bx{
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media screen and (max-width:1250px) {
    .ch-menu-bx{
        /* padding-right: 120px; */
        padding-right: 0;
    }
}

@media screen and (max-width:1150px) {

    .ch-inner .ch-right,
    .ch-inner .ch-left{
        width: auto;
    }

    /* --- @Menu Links Start --- */
    /* .ch-menu-bx{
        display: none;
    } */

    /* --- @Menu Links End --- */

}

@media screen and (max-width:1024px) {

    .custom-header{
        padding: 0 15px;
    }
    
}

@media screen and (max-width:700px) {
    /* --- @Menu Links Start --- */
    .ch-menu-bx{
        display: none;
    }
    /* --- @Menu Links End --- */
}

@media screen and (max-width:576px) {

    .ch-logo img{
        width: 120px
    }

    .signinup-btn-bx .sip-btn{
        padding: 6px 10px;
    }

    .menu-icon-bx{
        margin-left: 10px;
    }

    /* .signinup-btn-bx .sip-btn:not(.register-sip-btn){
        display: none;
    } */

    .custom-header{
        height: auto;
    }

    .translated-rtl .signinup-btn-bx .sip-btn.register-sip-btn,
    .translated-ltr .signinup-btn-bx .sip-btn.register-sip-btn{
        display: none;
    }
    
}

@media screen and (max-width:392px) {

    .ch-inner{
        padding: 10px 0;
    }

    .ch-logo img:nth-child(1){
        display: none;
    }

    .ch-logo img:nth-child(2){
        display: block;
        width: 50px;
        height: 50px;
        position: relative;
        top: 1px;
    }

}
/* --- @media Css End --- */