/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last  1024 versions
*/

/* --- Card Item Css Start --- */

.ci-flex-row{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
}

.ci-border{
    border: 1px solid var(--darkblue);
    -webkit-border-radius: 20px;
       -moz-border-radius: 20px;
            border-radius: 20px;
    padding: 20px;
}


.card-item .card-i-num{
    font-size: 44px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--electricblue);
}


.card-item .card-i-img{
    width: 48px;
    height: 48px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-bottom: 20px;
}

.card-item .card-i-img.img-circle{
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    padding: 20px;
    background-color: var(--darkblue);
}

.card-item .card-i-img.img-32{
    width: 32px;
    height: 32px;
}

.card-item .card-i-img.img-64{
    width: 64px;
    height: 64px;
}

.card-item .card-i-img.img-84{
    width: 84px;
    height: 84px;
}

.card-item .card-i-heading{
    font-size: 20px;
    color: var(--celeste);
    font-weight: 600;
}

@media screen and (max-width:576px) {
    .card-item .card-i-heading{
        font-size: 16px;
    }
}


.card-item .card-i-heading.heading-32{
    font-size: 32px;
}

.card-item .card-i-heading.color-electricblue{
    color: var(--electricblue);
}

.card-item .card-i-text{
    margin-top: 5px;
    font-size: 14px;
    color: var(--celeste-800);
}

.card-item .card-i-text.text-16{
    font-size: 16px;
}


.card-item .card-i-text.line-clamp3{
    display: -webkit-inline-box;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.card-i-link{
    font-size: 14px;
    margin-top: 10px;
    color: var(--celeste);
    text-decoration: underline;
    text-underline-offset: 6px;
}
/* --- Card Item Css End --- */


/* --- Card Item White Start --- */
.ci-white .card-i-heading{
    color: var(--azure);
}

.ci-white .card-i-heading.cih-darkblue{
    color: var(--darkblue);
}

.ci-white .card-i-text{
    font-weight: 500;
    color: var(--darkblue-800);
}
/* --- Card Item White End --- */

/* --- Card Item Money Start --- */
.card-item-money .card-item .card-i-text.max-width-189{
    max-width: 189px;
}

@media screen and (max-width:576px) {
    .card-item-money{
        text-align: center;
    }

    .card-item-money .card-item .card-i-text.max-width-189{
        max-width: inherit;
    }

    .card-item.funded-card-item .card-i-heading{
        font-size: 14px;
    }

    .card-item.funded-card-item .card-i-text{
        font-size: 12px;
    }
}
/* --- Card Item Money End --- */


/* --- Card Item Flip Bx Css Start --- */
.card-item-flip{
    padding-bottom: 25px;
    height: 100%;
    cursor: pointer;
}

.ctf-inner{
    position: relative;
    height: 100%;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.card-item-flip .card-item{
    border: 1px solid var(--electricblue-200);
    -webkit-border-radius: 20px;
       -moz-border-radius: 20px;
            border-radius: 20px;
    padding: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card-item-flip .cif-befor{
    position: absolute;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.card-item-flip .cif-after{
    background-color: var(--electricblue-200);
    height: 100%;
    -webkit-transform: rotateY(180deg);
       -moz-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.card-item-flip .card-item .card-i-img{
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
}

.card-item-flip .ctf-inner .cif-after{
    opacity: 0;
}

.card-item-flip .ctf-inner .cif-befor{
    opacity: 1;
}


.benefitsslider-bx .slick-slide .card-item-flip{
    height: 219px;
}

@media screen and (max-width:664px) {
    .benefitsslider-bx .slick-slide .card-item-flip{
        height: 240px;
    }
}

@media screen and (max-width:576px) {
    .benefitsslider-bx .slick-slide .card-item-flip{
        height: 192px;
    }
}

@media screen and (max-width:411px) {
    .benefitsslider-bx .slick-slide .card-item-flip{
        height: 213px;
    }
}

@media screen and (max-width:331px) {
    .benefitsslider-bx .slick-slide .card-item-flip{
        height: 234px;
    }
}


@media (max-width:768px) {
    .card-item-flip .cif-befor{
        opacity: 0 !important;
    }

    .card-item-flip .cif-after{
        opacity: 1 !important;
    }

    .card-item-flip .ctf-inner{
        -webkit-transform: rotateY(180deg);
           -moz-transform: rotateY(180deg);
                transform: rotateY(180deg);
    }
}
/* --- Card Item Flip Bx Css End --- */


/* --- Card Item moreshow Bx Css Start --- */

.card-item-showtext{
    position: relative;
    -webkit-border-radius: 32px;
       -moz-border-radius: 32px;
            border-radius: 32px;
    padding: 20px;
    margin: 0;
    -webkit-transform: scale(0.95);
       -moz-transform: scale(0.95);
        -ms-transform: scale(0.95);
         -o-transform: scale(0.95);
            transform: scale(0.95);
    background-color: var(--celeste-100);
    overflow: hidden;
    cursor: pointer;
}

.card-item-showtext::after{
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
       -moz-transform: translate(-50%, -50%) scale(0);
        -ms-transform: translate(-50%, -50%) scale(0);
         -o-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    width: 200%;
    height: 200%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    z-index: 1;
    background-color: var(--electricblue-200);
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.card-item-showtext .card-item{
    position: relative;
    z-index: 2;
    height: 262px;
    overflow: hidden;
}

.card-item-showtext .card-item .card-i-img{
    width: 84px;
    height: 84px;
    margin-top: 60px;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.card-item-showtext .card-item .card-i-text{
    display: -webkit-inline-box;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}




@media screen and (max-width:768px) {
    .card-item-showtext::after{
        -webkit-transform: translate(-50%, -50%) scale(1);
           -moz-transform: translate(-50%, -50%) scale(1);
            -ms-transform: translate(-50%, -50%) scale(1);
             -o-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
    }
    
    .card-item-showtext .card-item .card-i-img{
        width: 56px;
        height: 56px;
        margin-top: 10px;
        margin-bottom: 15px;
    }
}

/* --- Card Item moreshow Bx Css End --- */


/* --- Step Card Item Start --- */
.step-card-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.step-card-item .sci-outer{
    position: relative;
    padding: 0 10px;
}

.step-card-item .sci-outer .card-item .card-i-line{
    position: relative;
    width: 90%;
    height: 1px;
    background-color: var(--electricblue-500);
    position: absolute;
    left: 45%;
    top: 50px;
}
.step-card-item .sci-outer .card-item .card-i-line.ci-left-33{
    left: 33%;
}

.step-card-item .sci-outer .card-item .card-i-line::after{
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    width: 10px;
    height: 10px;
    background-color: rgb(6 118 144);
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.step-card-item .sci-outer .card-item .card-i-line::before{
    content: "";
    position: absolute;
    right: 0;
    top: -4px;
    width: 10px;
    height: 10px;
    background-color: rgb(6 118 144);
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
}

.step-card-item .sci-outer .card-item{
    max-width: 200px;
    width: 100%;
}

.step-card-item .sci-outer .card-item.ci-mxwidth-215px{
    max-width: 215px;
}

.step-card-item .sci-outer .card-item .card-i-num{
    font-size: 70px;
    font-weight: 300;
}

.step-card-item .sci-outer .card-item .card-i-text{
    font-size: 20px;
}

.step-card-item .sci-outer .card-item .card-i-text.ci-fontsize-16px{
    font-size: 16px;
}

.step-card-item .sci-outer:nth-child(1) .card-item .card-i-num{
    color: var(--celeste);
}

.step-card-item .sci-outer:nth-child(3) .card-item .card-i-num{
    color: var(--azure);
}

.step-card-item .sci-outer:nth-child(3) .card-item .card-i-line{
    display: none;
}

@media screen and (max-width:992px) {
    .step-card-item{
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .step-card-item .sci-outer .card-item.ci-mxwidth-215px,
    .step-card-item .sci-outer .card-item{
        width: 100%;
        max-width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 25px;
    }

    /* .step-card-item .sci-outer:nth-child(1) .card-item .card-i-line,
    .step-card-item .sci-outer:nth-child(2) .card-item .card-i-line,
    .step-card-item .sci-outer:nth-child(3) .card-item .card-i-line{
        display: none;
    } */

    .step-card-item .sci-outer .card-item .card-i-num{
        width: 30px;
        font-size: 60px;
        margin-right: 30px;
    }
    .step-card-item .sci-outer .card-item .card-i-line.ci-left-33,
    .step-card-item .sci-outer .card-item .card-i-line{
        left: 65px;
        top: 0;
        width: 1px;
        height: 100%;
    }

    .step-card-item .sci-outer .card-item .card-i-line::after{
        content: none;
    }

    .step-card-item .sci-outer .card-item .card-i-line::before{
        left: 50%;
        -webkit-transform: translate(-52%, -50%) rotate(45deg);
           -moz-transform: translate(-52%, -50%) rotate(45deg);
            -ms-transform: translate(-52%, -50%) rotate(45deg);
             -o-transform: translate(-52%, -50%) rotate(45deg);
                transform: translate(-52%, -50%) rotate(45deg);
        top: 50%;
    }

    .step-card-item .sci-outer:nth-child(1) .card-item .card-i-line::before{
        top: -4px;
        -webkit-transform: translate(-52%, -50%) rotate(45deg);
           -moz-transform: translate(-52%, -50%) rotate(45deg);
            -ms-transform: translate(-52%, -50%) rotate(45deg);
             -o-transform: translate(-52%, -50%) rotate(45deg);
                transform: translate(-52%, -50%) rotate(45deg);
    }

    .step-card-item .sci-outer:nth-child(3) .card-item .card-i-line::before{
        top: initial;
        bottom: -4px;
        -webkit-transform: translate(-52%, -50%) rotate(45deg);
           -moz-transform: translate(-52%, -50%) rotate(45deg);
            -ms-transform: translate(-52%, -50%) rotate(45deg);
             -o-transform: translate(-52%, -50%) rotate(45deg);
                transform: translate(-52%, -50%) rotate(45deg);
    }

    .step-card-item .sci-outer:nth-child(1) .card-item .card-i-line{
        display: block;
        height: 50%;
        top: inherit;
        bottom: 0px;
    }

    .step-card-item .sci-outer:nth-child(3) .card-item .card-i-line{
        display: block;
        height: 50%;
        top: 0;
    }
}
/* --- Step Card Item End --- */


.cedgsr{
    background-image: url("../img/Ellipse(1).png");
    background-position: top center;
    background-repeat: no-repeat;
    -webkit-background-size: contain;
       -moz-background-size: contain;
         -o-background-size: contain;
            background-size: contain;
}

.cedgsr.fcaegsr{
    background-image: url("../img/Ellipse(2).png");
}

.video-bg{
    position: absolute;
    left: 0;
    top: 150px;
    width: 100%;
    height: 450px;
    z-index: -1;
}

.video-bg::before,
.video-bg::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 150px;
    z-index: 1;
    background-image: -webkit-gradient(linear, left bottom, left top, from(var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(bottom, var(--darkblue), transparent);
    background-image: -moz-linear-gradient(bottom, var(--darkblue), transparent);
    background-image: -o-linear-gradient(bottom, var(--darkblue), transparent);
    background-image: linear-gradient(0deg, var(--darkblue), transparent);
}

.video-bg::before{
    bottom: initial;
    top: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(top, var(--darkblue), transparent);
    background-image: -moz-linear-gradient(top, var(--darkblue), transparent);
    background-image: -o-linear-gradient(top, var(--darkblue), transparent);
    background-image: linear-gradient(180deg, var(--darkblue), transparent);
}

.video-bg video{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: cover;
       object-fit: cover;
    opacity: 0.3;
}

.video-bg img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: cover;
       object-fit: cover;
    opacity: 0.3;
}


@media only screen and (min-width: 768px) {
    .card-item-flip:hover .ctf-inner{
        -webkit-transform: rotateY(180deg);
           -moz-transform: rotateY(180deg);
                transform: rotateY(180deg);
    }

    .card-item-flip:hover .ctf-inner .cif-befor{
        opacity: 0;
    }

    .card-item-flip:hover .ctf-inner .cif-after{
        opacity: 1;
    }

    .card-item-showtext:hover::after{
        -webkit-transform: translate(-50%, -50%) scale(1);
           -moz-transform: translate(-50%, -50%) scale(1);
            -ms-transform: translate(-50%, -50%) scale(1);
             -o-transform: translate(-50%, -50%) scale(1);
                transform: translate(-50%, -50%) scale(1);
    }
    
    .card-item-showtext:hover .card-item .card-i-img{
        width: 56px;
        height: 56px;
        margin-top: 10px;
        margin-bottom: 15px;
    }
}