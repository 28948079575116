/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last  1024 versions
*/


.ev-section .nav-indicato-bx{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: auto;
}

.ev-section .nav-indicato-bx .nib-item br{
    display: none;
}

.ev-section .ev-video-item{
    opacity: 1;
}

@media screen and (max-width:390px) {
    .ev-section .nav-indicato-bx .nib-item br{
        display: block;
    }
}

/* --- News card section start --- */
.news-card-item{
    display: block;
    position: relative;
    z-index: 1;
    padding: 15px;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    background-color: #22272C;
    border: 1px solid var(--electricblue-400);
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.news-card-item .nci-date,
.news-card-item .nci-read-time,
.news-card-item .nci-tag{
    font-size: 13px;
    font-weight: 400;
    color: var(--celeste-400);
    margin-top: 5px;
}

.news-card-item .nci-read-time,
.news-card-item .nci-tag{
    margin-top: 0;
}

.news-card-item .nci-date{
    margin-top: 20px;
}

.news-card-item .nci-heading{
    font-size: 18px;
    font-weight: 500;
    color: var(--celeste);
    margin-top: 5px;
    display: -webkit-inline-box;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.news-card-item .nci-text{
    font-size: 14px;
    font-weight: 400;
    color: var(--celeste-800);
    margin-top: 15px;
    display: -webkit-inline-box;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.news-card-item .nci-readmore{
    font-size: 13px;
    font-weight: 600;
    color: var(--electricblue);
    text-align: right;
    margin-top: 5px;
}

.news-card-item .nci-readmore img{
    display: inline-block;
    position: relative;
    top: -1px;
    width: 18px;
    height: 18px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-left: 3px;
}
/* --- News card section end --- */


/* --- News Card Section Style Two Start --- */
.nci-style-two{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    padding: 25px 0;
    border: none;
    -webkit-border-radius: 0;
       -moz-border-radius: 0;
            border-radius: 0;
    background: transparent;
    border-bottom: 1px solid var(--celeste-100);
}

.nci-style-two .nci-img-bx{
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 320px;
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.nci-style-two .nci-img-bx img{
    width: 100%;
}

.nci-style-two .nci-data-bx{
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 100%;
}

.nci-style-two .nci-tag{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    row-gap: 5px;
    -webkit-column-gap: 10px;
       -moz-column-gap: 10px;
            column-gap: 10px;
    margin-bottom: 10px;
}

.nci-style-two .nci-tag span{
    font-size: 14px;
    font-weight: 500;
}

.nci-style-two .nci-heading{
    font-size: 20px;
}

.nci-style-two .nci-text{
    margin-top: 5px;
    font-size: 12px;
}

.nci-style-two .nci-readmore{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 15px;
}

@media screen and (max-width:768px) {
    .nci-style-two .nci-img-bx{
        width: 280px;
    }
}

@media screen and (max-width:576px) {

    .nci-style-two .nci-img-bx{
        width: 100%;
    }

    .nci-style-two{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
       
    .nci-style-two .nci-readmore{
        margin-top: 25px;
    }
}

/* --- News Card Section Style Two End --- */


/* --- News Card Section Style Two Start --- */
.educationslider-card-outer{
    padding: 5px;
}

.educationslider-card-item{
    opacity: 0.3;
    -webkit-transform: scale(0.8);
       -moz-transform: scale(0.8);
        -ms-transform: scale(0.8);
         -o-transform: scale(0.8);
            transform: scale(0.8);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slick-center .educationslider-card-item{
    opacity: 1;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
}

.educationslider-card-item .eci-img-bx{
    overflow: hidden;
    margin-bottom: 15px;
}

.educationslider-card-item .eci-img-bx{
    position: relative;
    padding-top: 56%;
    background-color: var(--celeste-100);
    -webkit-border-radius: 15px;
       -moz-border-radius: 15px;
            border-radius: 15px;
}

.educationslider-card-item .eci-img-bx iframe{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
}

.educationslider-card-item .eci-img-bx img{
    width: 100%;
}

.educationslider-card-item .eci-data-bx{
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slick-center .educationslider-card-item .eci-data-bx{
    opacity: 1;
}

.educationslider-card-item .eci-heading{
    font-size: 20px;
    font-weight: 600;
    color: var(--celeste);
    display: -webkit-inline-box;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.educationslider-card-item .eci-text{
    font-size: 14px;
    font-weight: 400;
    color: var(--celeste-800);
    margin-top: 5px;
    display: -webkit-inline-box;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@media screen and (max-width:992px) {
    .educationslider-card-item{
        -webkit-transform: scale(.9);
           -moz-transform: scale(.9);
            -ms-transform: scale(.9);
             -o-transform: scale(.9);
                transform: scale(.9);
    }
}

@media screen and (max-width:768px) {
    .educationslider-card-item{
        -webkit-transform: scale(.95);
           -moz-transform: scale(.95);
            -ms-transform: scale(.95);
             -o-transform: scale(.95);
                transform: scale(.95);
    }
}

/* --- News Card Section Style Two End --- */


@media only screen and (min-width: 768px) {
    .news-card-item:not(.nci-style-two):hover{
        background-color: #156D81;
    }

    .nci-style-two .nci-img-bx:hover{
        -webkit-filter: brightness(1);
                filter: brightness(1);
    }
}



.ncr-animated{
    position: relative;
    height: 220px;
    overflow: hidden;
}

.ncr-animated::after{
    pointer-events: none;
    content: "";
    position: absolute;
    left: 0;
    bottom: -25px;
    z-index: 2;
    width: 100%;
    height: 50px;
    background-image: url("../img//Ellipse(4).png");
    background-position: center;
    -webkit-background-size: 80% 100%;
       -moz-background-size: 80% 100%;
         -o-background-size: 80% 100%;
            background-size: 80% 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    background-repeat: no-repeat;
}

.ncr-animated > div:nth-child(1){
    position: absolute;
    left: 5%;
    top: 30%;
    -webkit-transform: rotate(-6.93deg);
       -moz-transform: rotate(-6.93deg);
        -ms-transform: rotate(-6.93deg);
         -o-transform: rotate(-6.93deg);
            transform: rotate(-6.93deg);
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.ncr-animated > div:nth-child(2){
    position: absolute;
    left: 25%;
    top: 32%;
    -webkit-transform: rotate(5.67deg);
       -moz-transform: rotate(5.67deg);
        -ms-transform: rotate(5.67deg);
         -o-transform: rotate(5.67deg);
            transform: rotate(5.67deg);
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.ncr-animated > div:nth-child(3){
    position: absolute;
    right: 25%;
    top: 40%;
    -webkit-transform: rotate(-3.39deg);
       -moz-transform: rotate(-3.39deg);
        -ms-transform: rotate(-3.39deg);
         -o-transform: rotate(-3.39deg);
            transform: rotate(-3.39deg);
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.ncr-animated > div:nth-child(4){
    position: absolute;
    right: 5%;
    top: 35%;
    -webkit-transform: rotate(7.57deg);
       -moz-transform: rotate(7.57deg);
        -ms-transform: rotate(7.57deg);
         -o-transform: rotate(7.57deg);
            transform: rotate(7.57deg);
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}

.ncr-animated > div:hover{
    /* position: relative;
    top: 5%; */
    -webkit-transform: rotate(0);
       -moz-transform: rotate(0);
        -ms-transform: rotate(0);
         -o-transform: rotate(0);
            transform: rotate(0);
    z-index: 1;
}

.ncr-animated > div:nth-child(1):hover a{
    -webkit-transform: translateY(-25%);
       -moz-transform: translateY(-25%);
        -ms-transform: translateY(-25%);
         -o-transform: translateY(-25%);
            transform: translateY(-25%);
}

.ncr-animated > div:nth-child(2):hover a{
    -webkit-transform: translateY(-27%);
       -moz-transform: translateY(-27%);
        -ms-transform: translateY(-27%);
         -o-transform: translateY(-27%);
            transform: translateY(-27%);
}

.ncr-animated > div:nth-child(3):hover a{
    -webkit-transform: translateY(-35%);
       -moz-transform: translateY(-35%);
        -ms-transform: translateY(-35%);
         -o-transform: translateY(-35%);
            transform: translateY(-35%);
}

.ncr-animated > div:nth-child(4):hover a{
    -webkit-transform: translateY(-30%);
       -moz-transform: translateY(-30%);
        -ms-transform: translateY(-30%);
         -o-transform: translateY(-30%);
            transform: translateY(-30%);
}

/* --- Style - 3 --- */

.nci-style-three .news-card-item{
    padding: 5px;
    overflow: hidden;
}

.nci-style-three .news-card-item .nci-img{
    width: 100%;
    opacity: 0.7;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    height: 180px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
}

.nci-style-three .news-card-item:hover .nci-img{
    opacity: 1;
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}


@media only screen and (max-width: 992px) {
    .nci-style-three .news-card-item .nci-img{
        height: 140px;
    }
}

@media only screen and (max-width: 768px) {
    .nci-style-three .news-card-item .nci-img{
        height: 110px;
    }
}