/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last  1024 versions
*/

.trades-card-item{
    margin: 15px 0;
    padding: 20px;
    -webkit-border-radius: 25px;
       -moz-border-radius: 25px;
            border-radius: 25px;
    background-color: var(--celeste-100);
}

.tci-textdata{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.tci-textdata .tci-td-inner{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
}

.tci-textdata .tci-td-inner.tci-flex-column{
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: end;
       -moz-box-align: end;
        -ms-flex-align: end;
            align-items: end;
    gap: 0px;
}

.tci-textdata .tci-td-inner .tci-data-img{
    width: 48px;
    height: 48px;
}

.tci-textdata .tci-td-inner .tci-data-name{
    font-size: 18px;
    font-weight: 500;
    color: var(--celeste);
}

.tci-textdata .tci-td-inner .tci-data-name span{
    display: block;
    font-size: 13px;
    font-weight: 400;
    color: var(--celeste-600);
    margin-top: -3px;
}


.tci-textdata .tci-td-inner .tci-rate{
    font-size: 18px;
    font-weight: 600;
    color: var(--celeste);
}

.tci-textdata .tci-td-inner .tci-rate span{
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
    color: var(--celeste-600);
    vertical-align: super;
}

.tci-textdata .tci-td-inner .tci-status{
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin-top: -3px;
    color: var(--celeste-600);
}

.tci-textdata .tci-td-inner .tci-status.tci-green{
    color: #259845;
}

.tci-textdata .tci-td-inner .tci-status.tci-red{
    color: #983A25;
}

.tci-grap-bx{
    padding: 15px 0;
}

.tci-grap-bx img{
    width: 100%;
}

.tci-provider{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste-600);
    margin-top: 10px;
    margin-bottom: -5px;
}

.tci-provider img{
    position: relative;
    top: 0px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
}


/*  */
@media screen and (max-width:576px) {
    .trades-card-item{
        padding: 20px 10px;
    }
}