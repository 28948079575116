/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last  1024 versions
*/

.forexslider-card-outer{
    padding: 10px;
}

.forexslider-card-items{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-border-radius: 25px;
       -moz-border-radius: 25px;
            border-radius: 25px;
    background-color: var(--celeste-100);
}

.forexslider-card-items .fci-right{
    padding: 25px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 -webkit-calc(100% - 250px);
       -moz-box-flex: 1;
        -ms-flex: 1 0 calc(100% - 250px);
            flex: 1 0 calc(100% - 250px);
    width: -webkit-calc(100% - 250px);
    width: -moz-calc(100% - 250px);
    width: calc(100% - 250px);
}

.forexslider-card-items .fci-right img{
    width: 100%;
}

.forexslider-card-items .fci-left{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;  
    gap: 25px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 250px;
       -moz-box-flex: 0;
        -ms-flex: 0 1 250px;
            flex: 0 1 250px;
    width: 250px;
    padding: 20px;
    background-color: var(--darkblue-500);
}

.forexslider-card-items .fci-left .fci-img-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.forexslider-card-items .fci-left .fci-img-bx img{
    width: 48px;
    height: 48px;
    margin-right: -10px;
    position: relative;
}

.forexslider-card-items .fci-left .fci-img-bx img:nth-child(1){
    z-index: 1;
}

.forexslider-card-items .fci-left .fci-country-name{
    color: white;
    font-weight: 600;
    font-size: 20px;
}

.forexslider-card-items .fci-left .fci-country-name span{
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-top: -3px;
    color: var(--celeste-600);
}

.forexslider-card-items .fci-left .fci-rates .fci-rate{
    font-size: 20px;
    font-weight: 600;
    color: var(--celeste);
}

.forexslider-card-items .fci-left .fci-rates .fci-rate span{
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    color: var(--celeste-600);
}

.forexslider-card-items .fci-left .fci-rates .fci-status{
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-top: -1px;
    color: var(--celeste-600);
}

.forexslider-card-items .fci-left .fci-rates .fci-status.fci-green{
    color: #259845;
}

.forexslider-card-items .fci-left .fci-rates .fci-status.fci-red{
    color: #983A25;
}

.forexslider-card-items .fci-left .fci-provider{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste-600);
}

.forexslider-card-items .fci-left .fci-provider img{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

@media screen and (max-width:576px) {
    .forexslider-card-items{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .forexslider-card-items .fci-right{
        padding: 15px;
        -webkit-box-flex: inherit;
        -webkit-flex: inherit;
           -moz-box-flex: inherit;
            -ms-flex: inherit;
                flex: inherit;
        width: 100%;
    }
    .forexslider-card-items .fci-left{
        padding: 15px;
        -webkit-box-flex: inherit;
        -webkit-flex: inherit;
           -moz-box-flex: inherit;
            -ms-flex: inherit;
                flex: inherit;
        width: 100%;
    }
}