/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last  1024 versions
*/

/* --- Textlayout File Css Start --- */
.textlayout-main-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 50px 0;
}

.textlayout-main-bx .tlm-heading{
    font-size: 32px;
    color: var(--celeste);
    text-align: center;
    font-weight: 600;
}

.textlayout-main-bx .tlm-heading.heading-24{
    font-size: 24px;
}

.textlayout-main-bx .tlm-heading .tlm-h-color{
    color: var(--electricblue);
}

.textlayout-main-bx .tlm-sub-text{
    font-size: 16px;
    color: var(--celeste-600);
    text-align: center;
    max-width: 931px;
}

.textlayout-main-bx .tlm-btns{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 15px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.textlayout-main-bx .tlm-btns .tlm-btn-items{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 54px;
       -moz-border-radius: 54px;
            border-radius: 54px;
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
    color: var(--celeste);
    font-weight: 500;
    border: 1px solid transparent;
    cursor: pointer;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.textlayout-main-bx .tlm-btns .tlm-btn-items::after{
    content: "";
    position: absolute;
    inset: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(var(--darkblue-600)));
    background-image: -webkit-linear-gradient(transparent, var(--darkblue-600));
    background-image: -moz-linear-gradient(transparent, var(--darkblue-600));
    background-image: -o-linear-gradient(transparent, var(--darkblue-600));
    background-image: linear-gradient(transparent, var(--darkblue-600));
    -webkit-border-radius: 54px;
       -moz-border-radius: 54px;
            border-radius: 54px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.textlayout-main-bx .tlm-btns .tlm-btn-items span{
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.textlayout-main-bx .tlm-btns .tlm-btn-items img{
    position: relative;
    top: 0.5px;
    width: 18px;
    height: 18px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-left: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.textlayout-main-bx .tlm-btns .tlm-btn-items .whatappimg{
    margin: 0;
    margin-right: 5px;
    width: 22px;
    height: 22px;
}

.textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-fill{
    border-color: var(--electricblue-500);
    background: -webkit-gradient( linear, left top, right top, from(var(--electricblue-400)), to(var(--azure-400)) );
    background: -webkit-linear-gradient( left, var(--electricblue-400), var(--azure-400) );
    background: -moz-linear-gradient( left, var(--electricblue-400), var(--azure-400) );
    background: -o-linear-gradient( left, var(--electricblue-400), var(--azure-400) );
    background: linear-gradient( 90deg, var(--electricblue-400), var(--azure-400) );
}

.textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-outline{
    color: var(--electricblue);
    border-color: var(--electricblue-500);
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-outline .tbidata,
.textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-outline::before{
    content: attr(tbidata);
    position: absolute;
    inset: 0px;
    color: var(--celeste);
    background: var(--electricblue-500);
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    -webkit-transform: translateX(-100%);
       -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
         -o-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-outline::before{
    display: none;
}

.textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-none{
    color: var(--electricblue);
}


@media only screen and (min-width: 768px) {
    .textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-fill:hover::after{
        opacity: 1;
        visibility: visible;
    }

    .textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-outline:hover span{
        -webkit-transform: translateX(-webkit-calc(100% + 20px));
           -moz-transform: translateX(-moz-calc(100% + 20px));
            -ms-transform: translateX(calc(100% + 20px));
             -o-transform: translateX(calc(100% + 20px));
                transform: translateX(calc(100% + 20px));
    }
    
    .textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-outline:hover .tbidata,
    .textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-outline:hover::before{
        -webkit-transform: translateX(0);
           -moz-transform: translateX(0);
            -ms-transform: translateX(0);
             -o-transform: translateX(0);
                transform: translateX(0);
    }

    .textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-none:hover img{
        -webkit-transform: translateX(5px);
           -moz-transform: translateX(5px);
            -ms-transform: translateX(5px);
             -o-transform: translateX(5px);
                transform: translateX(5px);
    }
}

/* --- Textlayout File Css End --- */



/* --- Textlayout White --- */
.textlayout-white .textlayout-main-bx .tlm-sub-text,
.textlayout-white .textlayout-main-bx .tlm-heading{
    color: var(--darkblue);
}

.textlayout-white .textlayout-main-bx .tlm-btns .tlm-btn-items.tbi-fill{
    color: var(--white);
    border-color: var(--electricblue);
    background: -webkit-gradient( linear, left top, right top, from(var(--electricblue)), to(var(--azure)) );
    background: -webkit-linear-gradient( left, var(--electricblue), var(--azure) );
    background: -moz-linear-gradient( left, var(--electricblue), var(--azure) );
    background: -o-linear-gradient( left, var(--electricblue), var(--azure) );
    background: linear-gradient( 90deg, var(--electricblue), var(--azure) );
}


@media screen and (max-width:768px) {
    .textlayout-main-bx .tlm-heading{
        font-size: 28px;
    }

    .textlayout-main-bx .tlm-heading.heading-24 br{
        display: none;
    }
}

@media screen and (max-width:576px) {
    .textlayout-main-bx .tlm-heading{
        font-size: 24px;
    }

    .textlayout-main-bx .tlm-heading.heading-24{
        font-size: 20px;
    }

    .textlayout-main-bx .tlm-sub-text{
        font-size: 14px;
    }
}

@media screen and (max-width:450px) {
    .textlayout-main-bx .tlm-heading{
        font-size: 20px;
    }

    .textlayout-main-bx .tlm-sub-text br,
    .textlayout-main-bx .tlm-heading br{
        display: none;
    }

}