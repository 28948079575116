/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last  1024 versions
*/

/* --- Footer Css --- */

footer.main-footer{
    position: relative;
    z-index: 1;
}

.lighttheme footer.main-footer{
    background-color: var(--darkblue);
}

footer.main-footer > div{
    /* padding: 0 15px; */
    max-width: 1200px;
}

.container-lg.cl-footer{
    max-width: 1920px;
    padding-left: 15px;
    padding-right: 15px;
}

/* --- Footer logo Css --- */
.footer-logo img{
    width: 180px;
    margin-bottom: 15px;
    cursor: pointer;
}

/* --- Footer Heading Css --- */
.footer-heading{
    color: var(--celeste);
    font-size: 18px;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 8px;
}

.footer-heading.fh-t{
    font-size: 14px;
    margin-bottom: 3px;
}

.footer-heading img{
    width: 20px;
    height: 10px;
    object-position: left center;
    object-fit: contain;
    margin-top: -5px;
    margin-bottom: -5px;
}

.footer-sub-heading{
    color: var(--celeste-600);
    font-size: 14px;
    font-weight: 400;
    margin-right: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

/* .footer-sub-heading.fsh-t{
    font-size: 14px;
} */


/* --- footer Links Css --- */
.footer-links-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.footer-links-bx .footer-link{
    position: relative;
    color: var(--celeste-600);
    font-size: 14px;
    font-weight: 400;
    margin-right: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

/* .footer-links-bx .footer-link::after{
    content: " ";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: var(--color11);
}

.footer-links-bx .footer-link:last-child::after {
    content: none;
} */

.footer-links-bx .footer-link:hover {
    color: var(--celeste);
}

.footer-links-bx .footer-link.active {
    color: var(--celeste);
}



/* --- Footer copyright & mede-with section Css --- */
.footer-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0px 0 15px;
}

.copyright {
    color: var(--celeste);
    font-size: 14px;
}

.mede-with {
    color: var(--celeste);
    font-size: 14px;
    margin: 0;
}

.mede-with a {
    color: var(--celeste-600);
    font-weight: 300;
    opacity: 0.8;
}

.mede-with:hover a,
.mede-with a:hover {
    opacity: 1;
    color: #00d5ff;
}

.icons-box-made {
    width: 20px;
    height: 14px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    top: 3px;
}

.icons-box-made .fa{
    position: absolute;
}

.mede-with .fa:nth-child(1) {
    color: var(--celeste-600);
    font-size: 12px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 1;
}

.mede-with .fa:nth-child(2) {
    color: var(--celeste-600);
    font-size: 12px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
    -webkit-transform: scale(0);
       -moz-transform: scale(0);
        -ms-transform: scale(0);
         -o-transform: scale(0);
            transform: scale(0);
}

.mede-with:hover .fa:nth-child(1) {
    opacity: 0;
}

.mede-with:hover .fa:nth-child(2) {
    opacity: 1;
    color: #00d5ff;
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
}


@media screen and (max-width:700px) {
    .footer-bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    .copyright {
        margin-bottom: 10px;
    }
}


@media screen and (max-width:768px) {

    .footer-links-bx{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        margin: 0 -8px;
    }

    .footer-links-bx .footer-link{
        display: inline-block;
        padding: 5px 10px 5px 10px;
    }
    
    .footer-links-bx .footer-link::after{
        content: " ";
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
           -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
             -o-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 1px;
        height: 50%;
        background-color: var(--celeste-100);
    }
    
    .footer-links-bx .footer-link:last-child::after {
        content: none;
    }
}


/* --- Footer Contact Info Css --- */
.footer-contact-info{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 0px;
    margin-top: 5px;
    font-size: 14px;
    color: var(--celeste-600);
    padding-right: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.footer-contact-info.fci-nowrap{
    white-space: nowrap;
}

.footer-contact-lable{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: -7px;
    font-size: 12px;
    color: var(--celeste-800);
    transition: all 0.3s ease-in-out;
}

.footer-contact-lable img{
    width: 25px;
    height: 15px;
    object-position: left center;
    object-fit: contain;
    border-radius: 1px;
}

.footer-contact-info img{
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    opacity: 0.8;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.footer-contact-info:hover{
    color: var(--celeste);
}

.whatsapp-link .footer-contact-info img,
.footer-contact-info:hover img{
    opacity: 1;
}

/* --- Footer Bottom Css --- */

.footer-bottom-link {
    border-top: 1.5px solid var(--white-400);
    padding: 15px 0;
    margin-top: 30px;
}

.footer-bottom-link .footer-links-bx{
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 -8px;
}

.footer-bottom-link .footer-links-bx .footer-link{
    display: inline-block;
    padding: 5px 10px 5px 10px;
    color: var(--white-400);
}

.footer-bottom-link .footer-links-bx .footer-link.active,
.footer-bottom-link .footer-links-bx .footer-link:hover{
    color: var(--celeste);
}

.footer-bottom-link .footer-links-bx .footer-link::after{
    content: " ";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: var(--celeste-100);
}

.footer-bottom-link .footer-links-bx .footer-link:last-child::after {
    content: none;
}

/* --- End Footer Css --- */