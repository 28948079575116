
/* ---
Page analytical-tools css start
--- */

.analytical-img {
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(180deg, var(--electricblue-400), #1f3f54 66.67%, var(--electricblue-400));
}

/* ---
Page analytical-tools css End
--- */

/* ---
Page Trading calculator css start
--- */

.results-text {
    border-top: 1px solid var(--celeste-100);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin: 0px 10px;
    padding: 20px 0px;
    padding-left: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}
.results-text .results-text-heading {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

.results-border-r:not(:last-of-type) {
    border-right: 1px solid var(--celeste-100);
    padding-right: 20px;
}

.calculate-btn {
    justify-content: center;
    margin: auto;
    text-align: center;
    align-items: center;
    display: flex;
}

/* ---
Page Trading calculator css End
--- */

/* ---
Page Economic Calendar css start
--- */

.bordar-economic{
    border: 1px solid var(--celeste-100);
    margin: 24px 0px 24px 0px;
}

.accordion-heading p{
  display: contents;
}


@media screen and (max-width:776px) {
  .input-container label,
  .input-container input {
      /* width: 100%; */
      max-width: auto;
  }
}

/* ---
Page Economic Calendar css End
--- */
