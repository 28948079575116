html{
    scroll-behavior: initial !important;
}

*{
    text-decoration: none;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

a,a:hover{
    text-decoration: none;
    color: var(--white);
}

#chat-widget-container{
    z-index: 1000 !important;
}


/* Common colors */
:root{
    --white: #ffffff;
    --white-900: rgba(255, 255, 255, 0.9);
    --white-800: rgba(255, 255, 255, 0.8);
    --white-700: rgba(255, 255, 255, 0.7);
    --white-600: rgba(255, 255, 255, 0.6);
    --white-500: rgba(255, 255, 255, 0.5);
    --white-400: rgba(255, 255, 255, 0.4);
    --white-300: rgba(255, 255, 255, 0.3);
    --white-200: rgba(255, 255, 255, 0.2);
    --white-100: rgba(255, 255, 255, 0.1);

    --black: #000000;
    --black-900: rgba(0, 0, 0, 0.9);
    --black-800: rgba(0, 0, 0, 0.8);
    --black-700: rgba(0, 0, 0, 0.7);
    --black-600: rgba(0, 0, 0, 0.6);
    --black-500: rgba(0, 0, 0, 0.5);
    --black-400: rgba(0, 0, 0, 0.4);
    --black-300: rgba(0, 0, 0, 0.3);
    --black-200: rgba(0, 0, 0, 0.2);
    --black-100: rgba(0, 0, 0, 0.1);

    --darkblue: #0d1721;
    --darkblue-900: rgba(13, 23, 33, 0.9);
    --darkblue-800: rgba(13, 23, 33, 0.8);
    --darkblue-700: rgba(13, 23, 33, 0.7);
    --darkblue-600: rgba(13, 23, 33, 0.6);
    --darkblue-500: rgba(13, 23, 33, 0.5);
    --darkblue-400: rgba(13, 23, 33, 0.4);
    --darkblue-300: rgba(13, 23, 33, 0.3);
    --darkblue-200: rgba(13, 23, 33, 0.2);
    --darkblue-100: rgba(13, 23, 33, 0.1);

    --cyan:#00ffff;
    --cyan-900: rgba(0, 255, 255, 0.9);
    --cyan-800: rgba(0, 255, 255, 0.8);
    --cyan-700: rgba(0, 255, 255, 0.7);
    --cyan-600: rgba(0, 255, 255, 0.6);
    --cyan-500: rgba(0, 255, 255, 0.5);
    --cyan-400: rgba(0, 255, 255, 0.4);
    --cyan-300: rgba(0, 255, 255, 0.3);
    --cyan-200: rgba(0, 255, 255, 0.2);
    --cyan-100: rgba(0, 255, 255, 0.1);

    --electricblue:#00d5ff;
    --electricblue-900: rgba(0, 213, 255, 0.9);
    --electricblue-800: rgba(0, 213, 255, 0.8);
    --electricblue-700: rgba(0, 213, 255, 0.7);
    --electricblue-600: rgba(0, 213, 255, 0.6);
    --electricblue-500: rgba(0, 213, 255, 0.5);
    --electricblue-400: rgba(0, 213, 255, 0.4);
    --electricblue-300: rgba(0, 213, 255, 0.3);
    --electricblue-200: rgba(0, 213, 255, 0.2);
    --electricblue-100: rgba(0, 213, 255, 0.1);

    --azure:#007bff;
    --azure-900: rgba(0, 123, 255, 0.9);
    --azure-800: rgba(0, 123, 255, 0.8);
    --azure-700: rgba(0, 123, 255, 0.7);
    --azure-600: rgba(0, 123, 255, 0.6);
    --azure-500: rgba(0, 123, 255, 0.5);
    --azure-400: rgba(0, 123, 255, 0.4);
    --azure-300: rgba(0, 123, 255, 0.3);
    --azure-200: rgba(0, 123, 255, 0.2);
    --azure-100: rgba(0, 123, 255, 0.1);

    --celeste: #d2e5f1;
    --celeste-900: rgba(210, 229, 241, 0.9);
    --celeste-800: rgba(210, 229, 241, 0.8);
    --celeste-700: rgba(210, 229, 241, 0.7);
    --celeste-600: rgba(210, 229, 241, 0.6);
    --celeste-500: rgba(210, 229, 241, 0.5);
    --celeste-400: rgba(210, 229, 241, 0.4);
    --celeste-300: rgba(210, 229, 241, 0.3);
    --celeste-200: rgba(210, 229, 241, 0.2);
    --celeste-100: rgba(210, 229, 241, 0.1);
}


/* --- Website Fonts --- */
@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-SemiBold.ttf");
    font-weight: 600;
}


/* --- Body tag Css --- */
body{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    color: var(--celeste);
    background-color: var(--darkblue);
    /* overflow: hidden; */
    /* overflow-y: auto; */
}

.overflowhidden,
body.overflowhidden{
    overflow: hidden;
}

body.lighttheme{
    /* background-color: var(--white); */
    background-color: #fafafa;
}


/* --- Web Loader Css --- */
.web-preloader {
    background-color: var(--darkblue);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000;
    -webkit-background-size: 200% 200%;
       -moz-background-size: 200% 200%;
         -o-background-size: 200% 200%;
            background-size: 200% 200%;
    background-position: 0% 50%;
	-webkit-animation: preloader_gradient 2s infinite forwards;
	   -moz-animation: preloader_gradient 2s infinite forwards;
	     -o-animation: preloader_gradient 2s infinite forwards;
	        animation: preloader_gradient 2s infinite forwards;
}

@-webkit-keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-o-keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.web-preloader span.web-inner {
    width: 200px;
    height: 200px;
    line-height: 200px;
    margin: auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    z-index: 0;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--white);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.web-preloader span.web-inner img {
    width: 200px;
    height: 200px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    position: relative;
    -webkit-animation: gogoloader-2 5ms infinite;
       -moz-animation: gogoloader-2 5ms infinite;
         -o-animation: gogoloader-2 5ms infinite;
            animation: gogoloader-2 5ms infinite;
}

@-webkit-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}

@-moz-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
             transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
             transform: scale(1.2);
        opacity: 0;
    }
}

@-o-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -o-transform: scale(0);
           transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -o-transform: scale(1.2);
           transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
          -o-transform: scale(0);
             transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
          -o-transform: scale(1.2);
             transform: scale(1.2);
        opacity: 0;
    }
}

.deactivate span.web-inner {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(50px);
       -moz-transform: translateY(50px);
        -ms-transform: translateY(50px);
         -o-transform: translateY(50px);
            transform: translateY(50px);
    -webkit-transition: all 0.3s 0.5s ease-in-out;
    -o-transition: all 0.3s 0.5s ease-in-out;
    -moz-transition: all 0.3s 0.5s ease-in-out;
    transition: all 0.3s 0.5s ease-in-out;
}

.deactivate {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s 1s ease-in-out;
    -o-transition: all 0.3s 1s ease-in-out;
    -moz-transition: all 0.3s 1s ease-in-out;
    transition: all 0.3s 1s ease-in-out;
}


/* --- Skeletant Css Start --- */
.skeletant-bx {
    pointer-events: none;
}

.skeletant-design {
    background-color: var(--darkblue);
    background-image: linear-gradient(45deg, var(--black-200) 30%, var(--celeste-100) 50%, var(--black-200) 70%);
    background-position: left;
    background-repeat: repeat;
    background-size: 1200px;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: shine-lines 1s infinite linear;
    pointer-events: none;
    border-radius: 15px;
}

.sh-280{
    height: 280px;
}

@-webkit-keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}
/* --- Skeletant Css End --- */



/* --- main-wrapper --- */
.main-wrapper{
    min-height: -webkit-calc(100vh - 45.5px - 105px);
    min-height: -moz-calc(100vh - 45.5px - 105px);
    min-height: calc(100vh - 45.5px - 105px);
}


/* --- Common max width --- */
.maxwidth1920{
    max-width: 1920px;
    margin: auto;
}

/* --- Bootsrep Overaid Css Start --- */
.container-lg.cl-custome{
    max-width: 1140px;
}
/* --- Bootsrep Overaid Css End --- */


.relative{
    position: relative;
}

/* --- Playstores Buttons Start --- */
.playstores-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 15px;
}

.playstores-btn .playstores-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    padding: 15px;
    -webkit-border-radius: 12px;
       -moz-border-radius: 12px;
            border-radius: 12px;
    background-color: var(--darkblue-300);
    width: 220px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.playstores-btn .playstores-item:hover{
    background-color: var(--cyan-100);
}

.playstores-btn .playstores-item img{
    width: 50px;
    height: 50px;
}

.playstores-btn .playstores-item .playstores-data .psd-lable{
    font-size: 12px;
    color: var(--celeste-500);
    font-weight: 400;
}

.playstores-btn .playstores-item .playstores-data .psd-heading{
    font-size: 20px;
    color: var(--celeste);
    font-weight: 500;
}
/* --- Playstores Buttons End --- */


/* --- Header Top Strip Start --- */
.topstrip-bx{
    position: relative;
    padding: 10px;
    height: 45.5px;
}

.topstrip-bx::before,
.topstrip-bx::after{
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 70px;
    z-index: 1;
}

.topstrip-bx::after{
    left: 0;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(left, var(--darkblue) 50%, transparent);
    background-image: -moz-linear-gradient(left, var(--darkblue) 50%, transparent);
    background-image: -o-linear-gradient(left, var(--darkblue) 50%, transparent);
    background-image: linear-gradient(90deg, var(--darkblue) 50%, transparent);
}

.topstrip-bx::before{
    right: 0;
    background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(right, var(--darkblue) 50%, transparent);
    background-image: -moz-linear-gradient(right, var(--darkblue) 50%, transparent);
    background-image: -o-linear-gradient(right, var(--darkblue) 50%, transparent);
    background-image: linear-gradient(-90deg, var(--darkblue) 50%, transparent);
}

.topstrip-bx .slick-slide{
    margin-right: 10px;
}

.tsb-item{
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 600;
    font-size: 13px;
    background-color: var(--celeste-100);
    padding: 3px 8px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
}

.tsb-item > div{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex ;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
}

.tsb-item > div img{
    width: 18px;
    height: 18px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    padding: 0.5px;
}

.tsb-item .tsb-name img{
    margin-right: 5px;
}

.tsb-item .tsb-rate img{
    margin-left: 5px;
    padding: 0px;
}

.tsb-item .tsb-rate{
    margin: 0 12px;
}

/* --- Topstrip Bx White --- */
.lighttheme .tsb-item{
    background-color: var(--darkblue-100);
}

.lighttheme .topstrip-bx::after{
    left: 0;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, var(--white)), to(transparent));
    background-image: -webkit-linear-gradient(left, var(--white) 50%, transparent);
    background-image: -moz-linear-gradient(left, var(--white) 50%, transparent);
    background-image: -o-linear-gradient(left, var(--white) 50%, transparent);
    background-image: linear-gradient(90deg, var(--white) 50%, transparent);
}

.lighttheme .topstrip-bx::before{
    right: 0;
    background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, var(--white)), to(transparent));
    background-image: -webkit-linear-gradient(right, var(--white) 50%, transparent);
    background-image: -moz-linear-gradient(right, var(--white) 50%, transparent);
    background-image: -o-linear-gradient(right, var(--white) 50%, transparent);
    background-image: linear-gradient(-90deg, var(--white) 50%, transparent);
}

.lighttheme .tsb-item{
    color: var(--darkblue);
}


@media screen and (max-width:576px) {

    .topstrip-bx::before,
    .topstrip-bx::after{
        width: 30px;
    }

}
/* --- Header Top Strip End --- */


/* --- Social Share Icon & Social Icons start --- */
.social-icon{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 20px;
}

.social-icon{
    padding-bottom: 0;
}

.social-icon .si-item{
    padding: 0px 3px;
    text-align: center;
    cursor: pointer;
}

.social-icon .si-item .si-icon{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    overflow: hidden;
    margin: auto;
    font-size: 16px;
    color: var(--celeste-500);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon::after{
    content: ""; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    -webkit-transform: translate(-50%, -50%) scale(0);
       -moz-transform: translate(-50%, -50%) scale(0);
        -ms-transform: translate(-50%, -50%) scale(0);
         -o-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
    z-index: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon::before{
    content: ""; 
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    border: 2px solid var(--celeste-300);
    -webkit-transform: translate(-50%, -50%) scale(1);
       -moz-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
         -o-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    z-index: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon .fa{
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.social-icon .si-item:hover .si-icon{
    color: var(--celeste);
}

.social-icon .si-item:hover .si-icon::after{
    opacity: 1;
}

.social-icon .si-item:hover .si-icon::before{
    -webkit-transform: translate(-50%, -50%) scale(0);
       -moz-transform: translate(-50%, -50%) scale(0);
        -ms-transform: translate(-50%, -50%) scale(0);
         -o-transform: translate(-50%, -50%) scale(0);
            transform: translate(-50%, -50%) scale(0);
}

.social-icon .si-item:hover .si-icon::after{
    background-color: var(--electricblue-300);
    -webkit-transform: translate(-50%, -50%) scale(1);
       -moz-transform: translate(-50%, -50%) scale(1);
        -ms-transform: translate(-50%, -50%) scale(1);
         -o-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
}

/* .social-icon .si-item.fb-item .si-icon::after{
    background-color: #2645a9;
    background-image: -webkit-linear-gradient(45deg, #2645a9, #4e78ff);
    background-image: -moz-linear-gradient(45deg, #2645a9, #4e78ff);
    background-image: -o-linear-gradient(45deg, #2645a9, #4e78ff);
    background-image: linear-gradient(45deg, #2645a9, #4e78ff);
}

.social-icon .si-item.insta-item .si-icon::after{
    background-color: #dc2743;
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: -moz-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: -o-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

.social-icon .si-item.li-item .si-icon::after{
    background-color: #0077b5;
    background-image: -webkit-linear-gradient(45deg, #0077b5, #4e78ff);
    background-image: -moz-linear-gradient(45deg, #0077b5, #4e78ff);
    background-image: -o-linear-gradient(45deg, #0077b5, #4e78ff);
    background-image: linear-gradient(45deg, #0077b5, #4e78ff);
}

.social-icon .si-item.youtube-item .si-icon::after{
    background-color: #dd0021;
}

.social-icon .si-item.whatsapp-item .si-icon::after{
    background-color: #44be53;
    background-image: -webkit-linear-gradient(45deg, #09ad1d, #44be53);
    background-image: -moz-linear-gradient(45deg, #09ad1d, #44be53);
    background-image: -o-linear-gradient(45deg, #09ad1d, #44be53);
    background-image: linear-gradient(45deg, #09ad1d, #44be53);
} */

/* .social-icon .si-item.copy-item .si-icon::after,
.social-share-icon .ssi-item.copy-item .ssi-icon{
    background-color: var(--color5);
    background-image: linear-gradient(-45deg, var(--color5), var(--color6), var(--color7), var(--color8), var(--color9));
} */

/* --- Social icon white --- */
.social-white.social-icon .si-item .si-icon{
    color: var(--darkblue-600);
}

.social-white.social-icon .si-item .si-icon::before{
    border-color: var(--darkblue-200);
}

.social-white.social-icon .si-item:hover .si-icon{
    color: var(--darkblue);
}

.social-white.social-icon .si-item:hover .si-icon::after{
    background-color: var(--darkblue-200);
}

/* --- Social Share Icon & Social Icons End --- */



/* --- Common BG-img Start --- */
.bg-img{
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
       -moz-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
}

.bg-img-mount{
    background-image: url("../img/icons/mountvector.svg");
}

.bg-img-asiamap{
    background-image: url("../img/icons/asiamap.svg");
}

.textlayout-bg-bx{
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: -2;
    width: 100%;
}

.textlayout-bg-bx.aefiuhgaevse::before{
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1;
    width: 100%;
    background-color: var(--darkblue);
    height: 150%;
    -webkit-animation: faegsreg 3s linear forwards;
       -moz-animation: faegsreg 3s linear forwards;
         -o-animation: faegsreg 3s linear forwards;
            animation: faegsreg 3s linear forwards;
}

@-webkit-keyframes faegsreg {
    to{
        width: 0%;
    }
}

@-moz-keyframes faegsreg {
    to{
        width: 0%;
    }
}

@-o-keyframes faegsreg {
    to{
        width: 0%;
    }
}

@keyframes faegsreg {
    to{
        width: 0%;
    }
}


.textlayout-bg-bx.bdfhbsrh::after{
    opacity: 0.4;
}

.textlayout-bg-bx.tbb-bottom{
    top: 60%;
}

.textlayout-bg-bx::after{
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100%;
    height: -webkit-calc(100% + 100px);
    height: -moz-calc(100% + 100px);
    height: calc(100% + 100px);
    background-image: -webkit-gradient(linear, left top, right top, from(transparent),color-stop(var(--darkblue-800)), color-stop(var(--darkblue)), color-stop(var(--darkblue-800)), to(transparent));
    background-image: -webkit-linear-gradient(left, transparent,var(--darkblue-800), var(--darkblue), var(--darkblue-800), transparent);
    background-image: -moz-linear-gradient(left, transparent,var(--darkblue-800), var(--darkblue), var(--darkblue-800), transparent);
    background-image: -o-linear-gradient(left, transparent,var(--darkblue-800), var(--darkblue), var(--darkblue-800), transparent);
    background-image: linear-gradient(90deg, transparent,var(--darkblue-800), var(--darkblue), var(--darkblue-800), transparent);
    background-position: center;
}

.textlayout-bg-bx.affter-none::after{
    content: none;
}

.textlayout-bg-bx img{
    position: relative;
    width: 100%;
    margin: -3% 0;
    -o-object-fit: contain;
       object-fit: contain;
}

.textlayout-bg-bx.esgdsgsrxh::after{
    opacity: 0.5;
}

.textlayout-bg-bx img.afegsrghsdr{
    max-width: 992px;
    padding: 0 15px;
    width: 100%;
    -o-object-position: center;
       object-position: center;
    display: block;
    margin: 0 auto;
}

.textlayout-bg-bx.caehydgfvusyedgbf img{
    opacity: 0.25;
    max-width: 1200px;
    margin-top: 150px;
}


.textlayout-drop-img{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
}


.textlayout-drop-img img{
    position: absolute;
    opacity: 0.5;
}

.textlayout-drop-img img:nth-child(1){
    left: 10%;
    top: 20%;
}

.textlayout-drop-img img:nth-child(2){
    right: 10%;
    top: 10%;
}

.textlayout-drop-img img:nth-child(3){
    left: 15%;
    bottom: 10%;
}

.textlayout-drop-img img:nth-child(4){
    right: 15%;
    bottom: 0%;
}

@media screen and (max-width:992px) {
    .cdgdtheavdzgdrtjrdf{
        top: 0;
        -webkit-transform: initial;
           -moz-transform: initial;
            -ms-transform: initial;
             -o-transform: initial;
                transform: initial;
        overflow: hidden;
        padding: 15px 0;
    }
}

@media screen and (max-width:576px) {
    .textlayout-bg-bx.tbb-bottom,
    .textlayout-bg-bx{
        top: 0;
        -webkit-transform: initial;
           -moz-transform: initial;
            -ms-transform: initial;
             -o-transform: initial;
                transform: initial;
        overflow: hidden;
        padding: 15px 0;
    }

    .textlayout-bg-bx.caehydgfvusyedgbf{
        top: -50px;
    }

    .textlayout-bg-bx.caehydgfvusyedgbf img{
        margin-top: 0px;
    }

    .textlayout-bg-bx::after{
        content: none;
    }

    .textlayout-bg-bx.bdfhbsrh img{
        width: 200%;
        margin: 0;
    }
}

/* --- Common BG-img End --- */


/* --- Common section & Gradinyant section start --- */

.bg-darkblue{
    background-color: var(--darkblue);
}

.bg-darkblue-100{
    background-color: #f4f4f4;
}

.common-section.cs-bg-color{
    background-color: var(--celeste-200);
}

.common-section.cs-radius-32px{
    -webkit-border-radius: 32px;
       -moz-border-radius: 32px;
            border-radius: 32px;
}

.gradient-section{
    position: relative;
    background: -webkit-gradient(linear, left top, left bottom, from(var(--darkblue-400)), color-stop(33.33%, #1f3f54), color-stop(66.67%, #286c8c), to(var(--electricblue-400)));
    background: -webkit-linear-gradient(top, var(--darkblue-400), #1f3f54 33.33%, #286c8c 66.67%, var(--electricblue-400));
    background: -moz-linear-gradient(top, var(--darkblue-400), #1f3f54 33.33%, #286c8c 66.67%, var(--electricblue-400));
    background: -o-linear-gradient(top, var(--darkblue-400), #1f3f54 33.33%, #286c8c 66.67%, var(--electricblue-400));
    background: linear-gradient(180deg, var(--darkblue-400), #1f3f54 33.33%, #286c8c 66.67%, var(--electricblue-400));
    -webkit-border-radius: 32px;
       -moz-border-radius: 32px;
            border-radius: 32px;
    padding: 50px;
    overflow: hidden;
}

.gradient-section.gradient2{
    background: -webkit-gradient(linear, left top, left bottom, from(var(--electricblue-400)), color-stop(66.67%, #1f3f54), to(var(--electricblue-400)));
    background: -webkit-linear-gradient(top, var(--electricblue-400), #1f3f54 66.67%, var(--electricblue-400));
    background: -moz-linear-gradient(top, var(--electricblue-400), #1f3f54 66.67%, var(--electricblue-400));
    background: -o-linear-gradient(top, var(--electricblue-400), #1f3f54 66.67%, var(--electricblue-400));
    background: linear-gradient(180deg, var(--electricblue-400), #1f3f54 66.67%, var(--electricblue-400));
}

.gradient-section > *{
    position: relative;
    z-index: 1;
}

.gradient-section::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0d172166;
    z-index: 0;
    -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px);
}

@media screen and (max-width:768px) {
    .gradient-section{
        padding: 15px;
    }

    .gradient-section.sfvszdgbs{
        padding: 25px 20px 20px;
    }
}
/* --- Common section & Gradinyant section end --- */


/* --- Common Heading Bx Css Start --- */
.common-heading-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
}

.common-heading-bx.chb-border-bottom{
    padding-bottom: 10px;
    border-bottom: 1px solid var(--electricblue-400);
}

.common-heading-bx.chb-fd-row{
    gap: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.common-heading-bx .small-lable{
    font-size: 14px;
    color: var(--celeste-500);
    font-weight: 500;
}

.common-heading-bx .common-heading{
    font-size: 24px;
    color: var(--celeste);
    font-weight: 600;
}

.common-heading-bx .common-heading .ch-color{
    color: var(--electricblue);
}

.common-heading-bx .common-heading .ch-color-gradient{
    background: -webkit-linear-gradient(182.25deg, var(--azure), var(--cyan)  50%, var(--electricblue));
    background: -moz-linear-gradient(182.25deg, var(--azure), var(--cyan)  50%, var(--electricblue));
    background: -o-linear-gradient(182.25deg, var(--azure), var(--cyan)  50%, var(--electricblue));
    background: linear-gradient(267.75deg, var(--azure), var(--cyan)  50%, var(--electricblue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.common-heading-bx .common-heading .ch-size-big{
    font-size: 140%;
}

.common-heading-bx .common-sub-heading{
    font-size: 16px;
    color: var(--celeste-600);
}

/* --- Common Heading White --- */
.chb-white.common-heading-bx .common-heading{
    color: var(--darkblue);
}

.chb-white.common-heading-bx .common-sub-heading{
    font-weight: 500;
    color: var(--darkblue-600);
}


@media screen and (max-width:768px) {
    .common-heading-bx .common-sub-heading br,
    .common-heading br{
        display: none;
    }

    .common-heading-bx.chb-fd-row{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: inherit;
        -webkit-justify-content: inherit;
           -moz-box-pack: inherit;
            -ms-flex-pack: inherit;
                justify-content: inherit;
        /* align-items: inherit; */
    }
}

@media screen and (max-width:768px) {
    .common-heading-bx .common-heading{
        font-size: 20px;
    }

    .common-heading-bx .common-sub-heading{
        font-size: 14px;
    }
}


/* --- Common Heading Bx Css Start --- */


/* --- Common Button Css Start --- */
.common-btn-item{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 54px;
       -moz-border-radius: 54px;
            border-radius: 54px;
    height: 40px;
    padding: 0 15px;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--celeste);
    font-weight: 500;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item::after{
    content: "";
    position: absolute;
    inset: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(var(--darkblue-600)));
    background-image: -webkit-linear-gradient(transparent, var(--darkblue-600));
    background-image: -moz-linear-gradient(transparent, var(--darkblue-600));
    background-image: -o-linear-gradient(transparent, var(--darkblue-600));
    background-image: linear-gradient(transparent, var(--darkblue-600));
    -webkit-border-radius: 54px;
       -moz-border-radius: 54px;
            border-radius: 54px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item span{
    white-space:nowrap;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item img{
    position: relative;
    top: 0.5px;
    width: 18px;
    height: 18px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-left: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item svg{
    width: 18px;
    height: 18px;
    margin-right: 5px;
}

.common-btn-item.cbi-fill{
    border-color: var(--electricblue-500);
    background: -webkit-gradient( linear, left top, right top, from(var(--electricblue-400)), to(var(--azure-400)) );
    background: -webkit-linear-gradient( left, var(--electricblue-400), var(--azure-400) );
    background: -moz-linear-gradient( left, var(--electricblue-400), var(--azure-400) );
    background: -o-linear-gradient( left, var(--electricblue-400), var(--azure-400) );
    background: linear-gradient( 90deg, var(--electricblue-400), var(--azure-400) );
}


.common-btn-item.cbi-outline{
    color: var(--electricblue);
    border-color: var(--electricblue-500);
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item.cbi-outline::before{
    content: attr(cbidata);
    position: absolute;
    inset: 0px;
    color: transparent;
    background: var(--electricblue-500);
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transform: translateX(100%);
       -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
         -o-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item.cbi-none{
    color: var(--electricblue);
}




/* --- common Button white --- */
.cbi-white.common-btn-item.cbi-fill{
    color: var(--white);
    border-color: var(--electricblue);
    background: -webkit-gradient( linear, left top, right top, from(var(--electricblue)), to(var(--azure)) );
    background: -webkit-linear-gradient( left, var(--electricblue), var(--azure) );
    background: -moz-linear-gradient( left, var(--electricblue), var(--azure) );
    background: -o-linear-gradient( left, var(--electricblue), var(--azure) );
    background: linear-gradient( 90deg, var(--electricblue), var(--azure) );
}

.cbi-white.common-btn-item.cbi-outline{
    color: var(--darkblue);
    border-color: var(--darkblue);
}


.cbi-white.common-btn-item.cbi-outline::before{
    background-color: var(--darkblue);
}


@media only screen and (min-width: 768px) {

    .common-btn-item.cbi-fill:hover::after{
        opacity: 1;
        visibility: visible;
    }

    .common-btn-item.cbi-outline:hover span{
        /* transform: translateX(calc(100% + 20px)); */
        color: var(--celeste);
        z-index: 2;
    }
    
    .common-btn-item.cbi-outline:hover::before{
        -webkit-transform: translateX(0);
           -moz-transform: translateX(0);
            -ms-transform: translateX(0);
             -o-transform: translateX(0);
                transform: translateX(0);
    }

    .common-btn-item.cbi-none:hover img{
        -webkit-transform: translateX(5px);
           -moz-transform: translateX(5px);
            -ms-transform: translateX(5px);
             -o-transform: translateX(5px);
                transform: translateX(5px);
    }

    .cbi-white.common-btn-item.cbi-outline:hover{
        color: var(--white);
    }
    
}


/* --- Common Button Css End --- */


/* --- common date style css start --- */
.common-img-bx img{
    width: 100%;
}

.common-date-text{
    font-size: 18px;
    color: var(--celeste);
}

.common-date-text2{
    font-size: 16px;
    color: var(--celeste);
}


.common-date-ul{
    color: var(--celeste-600);
    padding: 0;
    list-style: none;
}

.common-date-ul li{
    position: relative;
    color: var(--celeste-600);
    padding: 5px 0px;
    padding-left: 20px;
}

.common-date-ul li::after{
    content: "";
    position: absolute;
    left: 2px;
    top: 12.3px;
    width: 8px;
    height: 8px;
    background-color: var(--cyan);
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
}
/* --- common date style css end --- */


/* --- Map Main Section Css Start --- */
.map-main-section.miness-margine{
    margin-bottom: -25%;
}

.map-main-section.top-miness-margine{
    margin-top: -10%;
}

.map-main-section img{
    width: 100%;
}
/* --- Map Main Section Css End --- */


/*  */
.about-img-section{
    background-color: var(--darkblue-700);
    height: 100%;
}

.about-img-section img{
    width: 100%;
    height: 100%;
}

@media screen and (max-width:768px) {
    .about-img-section img{
        max-width: 300px;
        height: 250px;
        margin: auto;
        display: block;
        padding: 0;
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: center;
           object-position: center;
    }
}


/* --- Property Group Img Start --- */
.property-group-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-top: -15px;
}

.property-group-img{
    position: relative;
    /* cursor: pointer; */
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33%;
    -webkit-filter: grayscale(1) opacity(0.3);
            filter: grayscale(1) opacity(0.3);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    aspect-ratio: 1 / 1;
    background-attachment: red;
    padding: 15px;
    -webkit-animation: filter-grayscale-img 3s ease-in-out infinite alternate;
    -moz-animation: filter-grayscale-img 3s ease-in-out infinite alternate;
      -o-animation: filter-grayscale-img 3s ease-in-out infinite alternate;
         animation: filter-grayscale-img 3s ease-in-out infinite alternate;
}

.property-group-img:hover{
    -webkit-filter: grayscale(0) opacity(1);
            filter: grayscale(0) opacity(1);
}

.property-group-img img{
    width: 80%;
    height: 80%;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
}

.property-group-bx > :nth-child(1) {
    -webkit-animation-delay: 0.3s;
    -moz-animation-delay: 0.3s;
      -o-animation-delay: 0.3s;
         animation-delay: 0.3s;
}

.property-group-bx > :nth-child(2) {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
      -o-animation-delay: 0.6s;
         animation-delay: 0.6s;
}

.property-group-bx > :nth-child(3) {
    -webkit-animation-delay: 0.9s;
    -moz-animation-delay: 0.9s;
      -o-animation-delay: 0.9s;
         animation-delay: 0.9s;
}

.property-group-bx > :nth-child(4) {
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s;
      -o-animation-delay: 1.2s;
         animation-delay: 1.2s;
}

.property-group-bx > :nth-child(5) {
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
      -o-animation-delay: 1.5s;
         animation-delay: 1.5s;
}

.property-group-bx > :nth-child(6) {
    -webkit-animation-delay: 1.8s;
    -moz-animation-delay: 1.8s;
      -o-animation-delay: 1.8s;
         animation-delay: 1.8s;
}

.property-group-bx > :nth-child(7) {
    -webkit-animation-delay: 2.1s;
    -moz-animation-delay: 2.1s;
      -o-animation-delay: 2.1s;
         animation-delay: 2.1s;
}

@-webkit-keyframes filter-grayscale-img {
    0% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    30% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    50% {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
    }

    70% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    100% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }
}

@-moz-keyframes filter-grayscale-img {
    0% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    30% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    50% {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
    }

    70% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    100% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }
}

@-o-keyframes filter-grayscale-img {
    0% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    30% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    50% {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
    }

    70% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    100% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }
}

@keyframes filter-grayscale-img {
    0% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    30% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    50% {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
    }

    70% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }

    100% {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
    }
}
/* --- Property Group Img End --- */


/* --- Silke Btn Css Start --- */
.slick-arrow{
    width: 35px;
    height: 35px;
    border: none;
    background-color:  var(--darkblue);
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    font-size: 24px;
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -moz-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
    border: 2px solid var(--electricblue);
    opacity: 0.5;
    padding: 5px;
    overflow: hidden;
    color: transparent;
    z-index: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slick-arrow::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    -webkit-background-size: 75% 75%;
       -moz-background-size: 75% 75%;
         -o-background-size: 75% 75%;
            background-size: 75% 75%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
}

.slick-arrow.slick-next.slick-disabled,
.slick-arrow.slick-prev.slick-disabled {
    opacity: 0.2 !important;
    visibility: visible !important;
    pointer-events: none;
}

.slick-arrow.slick-prev {
    position: absolute;
    right: 40px;
    top: -40px;
}

.slick-arrow.slick-prev::after {
    background-position: 3px center;
    background-image: url("../img/icons/arrowright.svg");
    -webkit-transform: rotate(180deg);
       -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
}

.slick-arrow.slick-next {
    position: absolute;
    right: 0px;
    top: -40px;
}

.slick-arrow.slick-next::after {
    background-position: 5px center;
    background-image: url("../img/icons/arrowright.svg");
}

.slick-arrow:hover{
    opacity: 1;
}

/* --- Slick Style Two --- */

.arrowtwo .slick-arrow.slick-prev{
    top: inherit;
    bottom: -40px;
    left: 50%;
    -webkit-transform: translateX(-webkit-calc(-50% - 20px));
       -moz-transform: translateX(-moz-calc(-50% - 20px));
        -ms-transform: translateX(calc(-50% - 20px));
         -o-transform: translateX(calc(-50% - 20px));
            transform: translateX(calc(-50% - 20px));
}

.arrowtwo .slick-arrow.slick-next{
    top: inherit;
    bottom: -40px;
    left: 50%;
    -webkit-transform: translateX(-webkit-calc(-50% + 20px));
       -moz-transform: translateX(-moz-calc(-50% + 20px));
        -ms-transform: translateX(calc(-50% + 20px));
         -o-transform: translateX(calc(-50% + 20px));
            transform: translateX(calc(-50% + 20px));
}

/* ---if width is full  Slick Style Two --- */

.arrowfullwidth .slick-arrow.slick-prev{
    right: 55px;
}

.arrowfullwidth .slick-arrow.slick-next{
    right: 15px;
}

/* --- Silke Btn Css End --- */


/* --- Slick Dots Start --- */
.slick-dots{
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0;
    margin: 0;
    margin: 5px 0px;
    padding: 0 8px;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 10px;
    z-index: 1;
}

.slick-dots li button{
    display: none;
}

.slick-dots li{
    list-style: none;
    width: 7px;
    height: 7px;
    background-color: var(--color4);
    margin: 2px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
}

.slick-dots li.slick-active{
    background-color: var(--white);
    width: 14px;
}
/* --- Slick Dots End --- */


/* -- FAQs Section Css Start --- */
.faqs-accordion{
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    margin-top: 20px;
    padding: 10px 5px;
}

.faqs-accordion .acc-item{
    padding: 0 0;
    border-bottom: 2px solid var(--celeste-200);
}

/* .faqs-accordion .acc-item:last-child {
    border-bottom: none;
} */

.faqs-accordion .acc-item .acc-heding{
    position: relative;
    font-size: 18px;
    padding: 15px 0px;
    padding-right: 30px;
    cursor: pointer;
    font-weight: 500;
    color: var(--celeste);
}

.faqs-accordion .acc-item .acc-heding.collapsed {
    opacity: 0.9;
}

.faqs-accordion .acc-item .acc-heding.collapsed:hover {
    opacity: 1;
}

.faqs-accordion .acc-item .acc-heding span.down-icon {
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    background-image: url("../img/icons/Minus.svg");
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: contain;
       -moz-background-size: contain;
         -o-background-size: contain;
            background-size: contain;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 26px;
    height: 26px;
    font-size: 20px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}



.faqs-accordion .acc-item .acc-heding.collapsed span.down-icon {
    -webkit-transform: translateY(-50%) rotate(180deg);
       -moz-transform: translateY(-50%) rotate(180deg);
        -ms-transform: translateY(-50%) rotate(180deg);
         -o-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
    background-image: url("../img/icons/Plus.svg");
}

.faqs-accordion .acc-item .acc-heding span.down-icon.di-two {
    background-image: url("../img/icons/Minus2.svg");
}

.faqs-accordion .acc-item .acc-heding.collapsed span.di-two {
    background-image: url("../img/icons/Plus2.svg");
}

.faqs-accordion .acc-item .faqs-data{
    color: var(--celeste-700);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
}

.faqs-accordion .acc-item .faqs-data strong{
    color: var(--celeste);
}

.faqs-accordion .acc-item .faqs-data a{
    color: var(--celeste);
    text-decoration: underline;
}

.faqs-accordion  .acc-item .faqs-data a:hover{
    color: var(--electricblue);
}

@media screen and (max-width:576px) {
    .faqs-accordion .acc-item .acc-heding{
        font-size: 16px;
    }
}


/* --- accordion-white --- */
.accordion-white .acc-item{
    border-bottom-color: var(--darkblue-100);
}

.accordion-white .acc-item .acc-heding{
    color: var(--darkblue);
}

.accordion-white .acc-item .faqs-data{
    color: var(--darkblue-700);
    font-weight: 500;
}

.accordion-white .acc-item .faqs-data strong{
    color: var(--darkblue);
}

.accordion-white .acc-item .faqs-data a{
    color: var(--darkblue);
    text-decoration: underline;
}

.accordion-white .acc-item .faqs-data a:hover{
    color: var(--electricblue);
}

.accordion-white .acc-item .acc-heding span.down-icon.di-two{
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
}

/* -- FAQs Section Css End --- */


/* --- Nav Indicato Bx Css Start --- */
.nav-indicato-bx{
    overflow: hidden;
    overflow-x: auto;
    border-bottom: 1px solid var(--electricblue-200);
}

.nav-indicato-bx .nib-inner{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
}

.nav-indicato-bx .nib-inner .nib-item{
    position: relative;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 0;
    color: var(--celeste-400);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
}

.nav-indicato-bx .nib-inner .nib-item::after{
    content: "";
    position: absolute;
    left: 0;
    top: -webkit-calc(100% - 1px);
    top: -moz-calc(100% - 1px);
    top: calc(100% - 1px);
    width: 100%;
    height: 1px;
    background-color: var(--electricblue);
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.nav-indicato-bx .nib-inner .nib-item:hover{
    color: var(--celeste);
}

.nav-indicato-bx .nib-inner .nib-item.active{
    color: var(--electricblue);
}

.nav-indicato-bx .nib-inner .nib-item.active::after{
    opacity: 1;
}


.signupwhite .nav-indicato-bx{
    border-color: var(--darkblue-100);
}

.signupwhite .nav-indicato-bx .nib-inner .nib-item::after{
    background-color: var(--darkblue);
}

.signupwhite .nav-indicato-bx .nib-inner .nib-item{
    color: var(--darkblue-800);
    font-weight: 600;
}

.signupwhite .common-btn-item{
    color: var(--darkblue);
    border-color: var(--darkblue-600);
    font-weight: 600;
    overflow: hidden;
}

.signupwhite .common-btn-item.cbi-outline::before{
    background-color: var(--darkblue);
}

.side-white  .close-icon,
.signupwhite .close-icon{
    color: var(--darkblue);
}

.side-white  .close-icon:hover,
.signupwhite .close-icon:hover{
    background-color: var(--darkblue-100);
}

.signupwhite form{
    overflow: hidden;
}

/* --- Nav Indicato Bx Css End --- */


/* --- Contact Page Css Start --- */
.contact-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 10px 10px;
    margin: 5px 0 !important;
    margin: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
}

.contact-info:hover {
    background-color:  var(--celeste-100);
}

.contact-info .contact-icon {
    padding: 2px;
    width: 40px;
    height: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    /* filter: invert(1); */
    opacity: 0.7;
}

.contact-info:hover .contact-icon {
    opacity: 1;
}

.contact-info .contact-icon img {
    width: 100%;
}

.contact-text-lable,
.contact-info .contact-text h6 {
    color: var(--celeste-600);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.73;
    overflow: hidden;
    margin: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: none;
}

.contact-info .contact-text p {
    color: var(--celeste-800);
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-info:hover .contact-text p {
    color: var(--celeste);
    text-decoration: underline;
}
/* --- Contact Page Css End --- */


.clientsslider-bx .cs-item{
    width: 170px !important;
    height: 100px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    margin: 25px 10px;
    overflow: hidden;
    display: inline-block;
    border: 1px solid rgba(238, 238, 238, 0);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
    /* opacity: 0.7; */
}

.clientsslider-bx .cs-item img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
      -o-transform: scale(1.1);
         transform: scale(1.1);
}

.clientsslider-bx .cs-item:hover{
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    border-color: var(--electricblue-500);
    -webkit-box-shadow: 0 0 26px -10px var(--electricblue-500);
       -moz-box-shadow: 0 0 26px -10px var(--electricblue-500);
            box-shadow: 0 0 26px -10px var(--electricblue-500);
    opacity: 1;
}


/* --- Our Team Bx Start --- */
.our-team-card-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    padding: 20px 0;
    border-bottom: 1px solid var(--darkblue-100);
}

.our-team-bx .our-team-card-item:last-child{
    border: none;
}

.our-team-bx .our-team-card-item:nth-child(odd){
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.our-team-bx .our-team-card-item:nth-child(even){
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
       -moz-box-orient: horizontal;
       -moz-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

.our-team-bx .our-team-card-item:nth-child(even) .otci-text{
    text-align: right;
}

.our-team-card-item .otci-img{
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 280px;
}

.our-team-card-item .otci-img img{
    width: 100%;
}

.our-team-card-item .otci-text{
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 5px;
    padding: 10px 0;
}

.our-team-card-item .otci-t-name{
    font-size: 24px;
    font-weight: 600;
    color: var(--darkblue);
}

.our-team-card-item .otci-t-position{
    font-size: 16px;
    font-weight: 500;
    color: var(--darkblue);
}

.our-team-card-item .otci-t-about{
    font-size: 16px;
    font-weight: 500;
    color: var(--darkblue-600);
}

@media screen and (max-width:576px) {
      
    .our-team-card-item .otci-img{
        max-width: 190px;
        width: 100%;
        height: 190px;
        -webkit-border-radius: 15px;
           -moz-border-radius: 15px;
                border-radius: 15px;
        overflow: hidden;
    }

    .our-team-card-item .otci-img img {
        width: 100%;
        height: 100%;
        -o-object-position: center;
           object-position: center;
        -o-object-fit: cover;
           object-fit: cover;
    }

    .our-team-card-item .otci-t-name{
        font-size: 18px;
    }

    .our-team-card-item .otci-t-about,
    .our-team-card-item .otci-t-position{
        font-size: 12px;
    }
}

@media screen and (max-width:450px) {       
    .our-team-card-item .otci-img{
        max-width: 150px;
    }
}

@media screen and (max-width:370px) {       

    .our-team-card-item .otci-img{
        max-width: 130px;
    }
}

/* --- Our Team Bx End --- */



.range-slider-bx{
    margin: 60px 0 25px;
}

.range-slider-bx *{
    -webkit-transition: none !important;
    -o-transition: none !important;
    -moz-transition: none !important;
    transition: none !important;
}

.range-slider-bx .input-range__label-container{
    position: relative;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    color: white;
    white-space: nowrap;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 12px;
    border: 1px solid var(--cyan-200);
    background-color: var(--cyan-200);
    left: -45%;
    top: -21px;
    font-family: 'Montserrat';
    padding-right: 50px;
}

.range-slider-value{
    display: none;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    color: white;
    white-space: nowrap;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid var(--cyan-200);
    background-color: var(--cyan-200);
    margin-bottom: 15px;
    margin-right: -5px;
}

.range-slider-bx .input-range__label-container::after{
    content: "Client";
    position: absolute;
    right: 9px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
}

.input-range__label.input-range__label--max,
.input-range__label.input-range__label--min{
    display: none;
}

.range-slider-bx .input-range__track--active{
    background-color: var(--electricblue);
}

.range-slider-bx .input-range__track{
    height: 4px;
}

.range-slider-bx .input-range__slider{
    background-color: var(--electricblue) !important;
    border-color: var(--electricblue) !important;
    width: 24px;
    height: 24px;
    margin-top: -0.9rem;
    margin-left: -0.8rem;
}

@media screen and (max-width:992px) {
    .range-slider-bx{
        padding: 0 8px;
    }
    .range-slider-bx .input-range__label-container{
        display: none;
    }

    .range-slider-bx{
        margin: 25px 0 25px;
    }

    .range-slider-value{
        display: block;
        margin-left: auto;
    }
}

/*  */

/* --- --- */
.signup-bx{
    width: 320px;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--darkblue);
    z-index: 111;
    padding: 15px 15px 50px;
    -webkit-border-radius: 0px 0 0 0px;
       -moz-border-radius: 0px 0 0 0px;
            border-radius: 0px 0 0 0px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(100%);
       -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
         -o-transform: translateX(100%);
            transform: translateX(100%);
}

.signup-bx.active{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
       -moz-transform: translateX(0);
        -ms-transform: translateX(0);
         -o-transform: translateX(0);
            transform: translateX(0);
}

.signup-header{
    background: rgb(25 35 45);
    height: 55px;
    position: -webkit-sticky;
    position: sticky;
    width: -webkit-calc(100% + 15px + 15px);
    width: -moz-calc(100% + 15px + 15px);
    width: calc(100% + 15px + 15px);
    top: 0px;
    -webkit-transform: translate(-15px, -15px);
       -moz-transform: translate(-15px, -15px);
        -ms-transform: translate(-15px, -15px);
         -o-transform: translate(-15px, -15px);
            transform: translate(-15px, -15px);
    z-index: 11;
}

.signupwhite .signup-header{
    background: #fcfcfc;
    border-bottom: 1px solid var(--darkblue-100);
}


.signup-bx .nib-inner{
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.signup-bx .nib-inner .nib-item{
    width: 50%;
    text-align: center;
    padding: 15px 0;
    font-weight: 500;
}

.signinup-overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #0000004d;
    top: 0;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    margin: 0!important;
    z-index: 110;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signinup-overlay.active{
    opacity: 1;
    visibility: visible;
}

@media screen and (max-width:400px) {
    .signup-bx{
        max-width: 100%;
        width: 100%;
    }
}


/* --- Close Btn Css Start --- */
.close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--electricblue);
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.close-icon:hover{
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg);
}

@media screen and (min-width:700px) {
    .close-icon:hover{
        background-color: var(--electricblue-200);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    .close-icon:active{
        background: var(--electricblue-200);
    }
}
/* --- Close Btn Css End --- */


/* --- Overlaydiv Css Start --- */
.serach-overlay,
.overlaydiv {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    top: 0;
    cursor: pointer;
    margin: 0 !important;
    z-index: 110;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.serach-overlay.active,
.overlaydiv.active {
    visibility: visible;
    opacity: 1;
}
/* --- Overlaydiv Css End --- */



.signupwhite.signup-bx{
    background-color: var(--white);
}




/*  ---- ----- */
.slidersidebox{
    position: relative;
}

.slidersidebox::before,
.slidersidebox::after{
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 14%;
    z-index: 1;
}

.slidersidebox::after{
    left: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(left, var(--darkblue), transparent);
    background-image: -moz-linear-gradient(left, var(--darkblue), transparent);
    background-image: -o-linear-gradient(left, var(--darkblue), transparent);
    background-image: linear-gradient(90deg, var(--darkblue), transparent);
}

.slidersidebox::before{
    right: 0;
    background-image: -webkit-gradient(linear, right top, left top, from(var(--darkblue)), to(transparent));
    background-image: -webkit-linear-gradient(right, var(--darkblue), transparent);
    background-image: -moz-linear-gradient(right, var(--darkblue), transparent);
    background-image: -o-linear-gradient(right, var(--darkblue), transparent);
    background-image: linear-gradient(-90deg, var(--darkblue), transparent);
}

@media screen and (max-width:1600px) {
    .slidersidebox::before,
    .slidersidebox::after{
        width: 12.5%;
    }
}

@media screen and (max-width:1200px) {
    .slidersidebox::before,
    .slidersidebox::after{
        width: 6%;
    }
}

@media screen and (max-width:992px) {
    .slidersidebox::before,
    .slidersidebox::after{
        width: 3%;
    }
}
/*  ---- ----- */


/* --- Breadcrumb Css Start --- */
.breadcrumb-bx{   
    display: -webkit-inline-box;   
    display: -webkit-inline-flex;   
    display: -moz-inline-box;   
    display: -ms-inline-flexbox;   
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    white-space: nowrap;
    width: 100%;
}

.breadcrumb-bx .breadcrumb-link{
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
    color: var(--celeste-700);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    font-weight: 500;
    color: var(--celeste-400);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link:hover{
    color: var(--electricblue);
    cursor: pointer;
}


.lighttheme .breadcrumb-bx .breadcrumb-link{
    color: var(--darkblue-700);
    font-weight: 600;
}

.lighttheme .breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    font-weight: 600;
    color: var(--darkblue-400);
}

.lighttheme .breadcrumb-bx .breadcrumb-link:hover{
    color: var(--electricblue);
}
/* --- Breadcrumb Css End --- */


@media screen and (max-width:576px) {
    .width576full{
        width: 100%;
    }
}




/* --- Lagal pages css start --- */
.legal-content .main-lable {
    font-weight: 600;
    margin-top: 30px;
    color: var(--electricblue);
    font-size: 20px;
    text-transform: capitalize;
}

.legal-content .main-sub-lable {
    margin-top: 30px;
    font-weight: 500;
    color: var(--electricblue);
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.legal-content ul li,
.legal-content p {
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
    margin-top: 10px;
    color: var(--celeste-600);
    font-weight: 300;
    text-align: justify;
}

.legal-content ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin-left: 20px;
    margin-top: 10px;
}

.legal-content ul.ul-dick {
    list-style: disc;
}

.legal-content ul.ul-alpha {
    list-style: lower-alpha;
}

.legal-content ul.ul-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.legal-content ul.ul-flex li {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding-right: 30px;
    text-align: left;
}

@media screen and (max-width:992px) {
    .legal-content ul.ul-flex li {
        width: 33.33%;
    }
}

@media screen and (max-width:768px) {
    .legal-content ul.ul-flex li {
        width: 50%;
    }
}

@media screen and (max-width:450px) {
    .legal-content ul.ul-flex li {
        width: 100%;
        padding-right: 0px;
    }
}


.legal-content ul li a,
.legal-content p a {
    text-decoration: underline;
    color: var(--celeste);
}

.legal-content ul a:hover,
.legal-content p a:hover {
    color: var(--electricblue);
}

.legal-content ul strong,
.legal-content p strong {
    color: var(--celeste);
}




.modal{
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.modal.fade.zoom .modal-dialog{
    -webkit-transform: scale(0.9);
       -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
         -o-transform: scale(0.9);
            transform: scale(0.9);
}

.modal.zoom.show .modal-dialog {
    -webkit-transform: none;
       -moz-transform: none;
        -ms-transform: none;
         -o-transform: none;
            transform: none;
}

.modal-content.custom-content{
    background-color: var(--darkblue);
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
       -moz-box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
            box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
}

.custom-modal-header{
    position: -webkit-sticky;
    position: sticky;
    padding: 25px 15px 15px;
}

.custom-modal-header .cmh-lable{
    font-size: 24px;
    color: var(--electricblue);
    font-weight: 600;
    text-align: center;
}

.custom-modal-header .cmh-sub-lable{
    font-size: 16px;
    color: var(--celeste-600);
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
}


.caegsr{
    position: absolute;
    left: 50%;
    top: -webkit-calc(0px - 70%);
    top: -moz-calc(0px - 70%);
    top: calc(0px - 70%);
    width: 100%;
    z-index: -1;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
    max-width: 1600px;
}

@media screen and (max-width:992px) {
    .caegsr{
        top: -webkit-calc(0px - 60%);
        top: -moz-calc(0px - 60%);
        top: calc(0px - 60%);
    }
}

@media screen and (max-width:768px) {
    .caegsr{
        top: -webkit-calc(0px - 50%);
        top: -moz-calc(0px - 50%);
        top: calc(0px - 50%);
    }
}

@media screen and (max-width:768px) {
    .caegsr{
        top: -webkit-calc(0px - 40%);
        top: -moz-calc(0px - 40%);
        top: calc(0px - 40%);
    }
}


.efgrsghedtr {
    /* style={{height: '96px', display: 'flex', alignItems: 'center', }} */
    overflow: hidden !important;
    padding-top: 5px;
}

.efgrsghedtr .text-transition{
    height: auto !important;
}





/* ---- */
.vsdgfhtjhdtrhsr{
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    z-index: -5;
    overflow: hidden;
    pointer-events: none;
}

.cadgfesrg.vsdgfhtjhdtrhsr{
    top: 70px;
}

.vsdgfhtjhdtrhsr::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    /* background-color: var(--darkblue); */
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(5%, var(--darkblue)), color-stop(50%, var(--darkblue-600)), color-stop(95%, var(--darkblue)));
    background-image: -webkit-linear-gradient(bottom, var(--darkblue) 5%, var(--darkblue-600) 50%, var(--darkblue) 95%);
    background-image: -moz-linear-gradient(bottom, var(--darkblue) 5%, var(--darkblue-600) 50%, var(--darkblue) 95%);
    background-image: -o-linear-gradient(bottom, var(--darkblue) 5%, var(--darkblue-600) 50%, var(--darkblue) 95%);
    background-image: linear-gradient(0deg, var(--darkblue) 5%, var(--darkblue-600) 50%, var(--darkblue) 95%);
}

.vsdgfhtjhdtrhsr::before{
    content: "";
    width: 100%;
    max-width: 1400px;
    position: absolute;
    left: 50%;
    top: 0px;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(-50%, 0);
       -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
         -o-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(1%, var(--darkblue)), color-stop(10%, transparent), color-stop(50%, transparent), color-stop(90%, transparent), color-stop(99%, var(--darkblue)));
    background-image: -webkit-linear-gradient(left, var(--darkblue) 1%, transparent 10%, transparent 50%, transparent 90%, var(--darkblue) 99%);
    background-image: -moz-linear-gradient(left, var(--darkblue) 1%, transparent 10%, transparent 50%, transparent 90%, var(--darkblue) 99%);
    background-image: -o-linear-gradient(left, var(--darkblue) 1%, transparent 10%, transparent 50%, transparent 90%, var(--darkblue) 99%);
    background-image: linear-gradient(90deg, var(--darkblue) 1%, transparent 10%, transparent 50%, transparent 90%, var(--darkblue) 99%);
}

.vsdgfhtjhdtrhsr.v-before-none::before{
    max-width: 2560px;
}

.vsdgfhtjhdtrhsr img{
    margin: auto;
    width: 100%;
    max-width: 1400px;
    display: block;
}

.vsdgfhtjhdtrhsr video{
    margin: auto;
    width: 100%;
    max-width: 1400px;
    display: block;
}

@media screen and (max-width:992px) {
    .vsdgfhtjhdtrhsr{
        top: 0px;
    }

    .vsdgfhtjhdtrhsr::before{
        content: none;
    }
}


@media screen and (max-width:768px) {
    .vsdgfhtjhdtrhsr{
        top: 0%;
    }

    .faegfase.vsdgfhtjhdtrhsr{
        top: 0%;
    }

    .cadgfesrg.vsdgfhtjhdtrhsr{
        top: 70px;
    }

    .vsdgfhtjhdtrhsr::after{
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(5%, var(--darkblue)), color-stop(50%, var(--darkblue-500)), color-stop(95%, var(--darkblue)));
        background-image: -webkit-linear-gradient(bottom, var(--darkblue) 5%, var(--darkblue-500) 50%, var(--darkblue) 95%);
        background-image: -moz-linear-gradient(bottom, var(--darkblue) 5%, var(--darkblue-500) 50%, var(--darkblue) 95%);
        background-image: -o-linear-gradient(bottom, var(--darkblue) 5%, var(--darkblue-500) 50%, var(--darkblue) 95%);
        background-image: linear-gradient(0deg, var(--darkblue) 5%, var(--darkblue-500) 50%, var(--darkblue) 95%);
    }
}

/* @media screen and (max-width:576px) {
    .vsdgfhtjhdtrhsr::after{
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(5%, var(--darkblue)), color-stop(50%, var(--darkblue-300)), color-stop(95%, var(--darkblue)));
        background-image: -webkit-linear-gradient(bottom, var(--darkblue) 5%, var(--darkblue-300) 50%, var(--darkblue) 95%);
        background-image: -moz-linear-gradient(bottom, var(--darkblue) 5%, var(--darkblue-300) 50%, var(--darkblue) 95%);
        background-image: -o-linear-gradient(bottom, var(--darkblue) 5%, var(--darkblue-300) 50%, var(--darkblue) 95%);
        background-image: linear-gradient(0deg, var(--darkblue) 5%, var(--darkblue-300) 50%, var(--darkblue) 95%);
    }
} */
/* ---- */


/* --- SignInUpBtns-Item Start --- */

#SignInBtns .modal-dialog .modal-content,
#SignUpBtns .modal-dialog .modal-content{
    max-width: 420px;
    margin: auto;
}

.sibwcbi{
    width: 100%;
    max-width: 300px;
    display: block;
    margin: 0 auto 16px;
    height: 50px;
    font-size: 15px;
}

.sibwcbi svg{
    width: 24px;
    height: 24px;
    -webkit-transform: rotate(180deg);
       -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
    margin-right: 5px;
}
/* --- SignInUpBtns-Item End --- */


/* --- --- */
.brochure-main-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.brochure-main-bx .bmb-img{
    width: 100%;
    max-width: 220px;
    margin-right: 20px;
}

.brochure-main-bx .bmb-btns > *{
    width: 162px;
    margin: 10px 0;
}


@media screen and (max-width:360px) {
    .brochure-main-bx{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .brochure-main-bx .bmb-btns{
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        gap: 5px;
    }

    .brochure-main-bx .bmb-btns > *{
        width: auto;
    }
}

/* --- --- */


/* ---
Img like a book design css 
--- */

.book-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
            justify-content: center;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
         perspective: 600px;
    padding: 30px 10px;
}


.book-big {
    cursor: pointer;
    width: 100%;
    padding-top: 145%;
    position: relative;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
         transform-style: preserve-3d;
    -webkit-transform: rotateY(-20deg);
    -moz-transform: rotateY(-20deg);
         transform: rotateY(-20deg);
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    transition: 0.5s ease;
}

.book-big:hover {
    -webkit-transform: rotateY(-25deg);
    -moz-transform: rotateY(-25deg);
         transform: rotateY(-25deg);
}

.book-big > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: top center;
       object-position: top center;
    -webkit-transform: translateZ(25px);
    -moz-transform: translateZ(25px);
         transform: translateZ(25px);
    background-color: var(--darkblue);
    -webkit-border-radius: 0 2px 2px 0;
       -moz-border-radius: 0 2px 2px 0;
            border-radius: 0 2px 2px 0;
}

.book-big::before {
    position: absolute;
    content: ' ';
    background-color: #006795;
    right: -8%;
    top: 3%;
    width: -webkit-calc(100% - 80%);
    width: -moz-calc(100% - 80%);
    width: calc(100% - 80%);
    height: 94%;
    -webkit-transform: rotateY(90deg);
       -moz-transform: rotateY(90deg);
            transform: rotateY(90deg);
    background: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(5%, #f9f9f9), color-stop(10%, #fff), color-stop(15%, #f9f9f9), color-stop(20%, #fff), color-stop(25%, #f9f9f9), color-stop(30%, #fff), color-stop(35%, #f9f9f9), color-stop(40%, #fff), color-stop(45%, #f9f9f9), color-stop(50%, #fff), color-stop(55%, #f9f9f9), color-stop(60%, #fff), color-stop(65%, #f9f9f9), color-stop(70%, #fff), color-stop(75%, #f9f9f9), color-stop(80%, #fff), color-stop(85%, #f9f9f9), color-stop(90%, #fff), color-stop(95%, #f9f9f9), to(#fff));
    background: -webkit-linear-gradient(left, #fff 0%, #f9f9f9 5%, #fff 10%, #f9f9f9 15%, #fff 20%, #f9f9f9 25%, #fff 30%, #f9f9f9 35%, #fff 40%, #f9f9f9 45%, #fff 50%, #f9f9f9 55%, #fff 60%, #f9f9f9 65%, #fff 70%, #f9f9f9 75%, #fff 80%, #f9f9f9 85%, #fff 90%, #f9f9f9 95%, #fff 100%);
    background: -moz-linear-gradient(left, #fff 0%, #f9f9f9 5%, #fff 10%, #f9f9f9 15%, #fff 20%, #f9f9f9 25%, #fff 30%, #f9f9f9 35%, #fff 40%, #f9f9f9 45%, #fff 50%, #f9f9f9 55%, #fff 60%, #f9f9f9 65%, #fff 70%, #f9f9f9 75%, #fff 80%, #f9f9f9 85%, #fff 90%, #f9f9f9 95%, #fff 100%);
    background: -o-linear-gradient(left, #fff 0%, #f9f9f9 5%, #fff 10%, #f9f9f9 15%, #fff 20%, #f9f9f9 25%, #fff 30%, #f9f9f9 35%, #fff 40%, #f9f9f9 45%, #fff 50%, #f9f9f9 55%, #fff 60%, #f9f9f9 65%, #fff 70%, #f9f9f9 75%, #fff 80%, #f9f9f9 85%, #fff 90%, #f9f9f9 95%, #fff 100%);
    background: linear-gradient(90deg, #fff 0%, #f9f9f9 5%, #fff 10%, #f9f9f9 15%, #fff 20%, #f9f9f9 25%, #fff 30%, #f9f9f9 35%, #fff 40%, #f9f9f9 45%, #fff 50%, #f9f9f9 55%, #fff 60%, #f9f9f9 65%, #fff 70%, #f9f9f9 75%, #fff 80%, #f9f9f9 85%, #fff 90%, #f9f9f9 95%, #fff 100%);
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
}

.book-big::after {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(-25px);
    -moz-transform: translateZ(-25px);
         transform: translateZ(-25px);
    background-color: #fff;
    -webkit-border-radius: 0 2px 2px 0;
       -moz-border-radius: 0 2px 2px 0;
            border-radius: 0 2px 2px 0;
    -webkit-box-shadow: -20px 0 20px 0px #666666;
    -moz-box-shadow: -20px 0 20px 0px #666666;
         box-shadow: -20px 0 20px 0px #666666;
}


/* ---
Book detail section css
--- */


/* --- 
Lightgallery start 
--- */

.lg-counter{
    color: white !important;
}

.lg-toolbar .lg-icon{
    color: rgba(255, 255, 255, 0.7) !important;
}

.lg-toolbar .lg-icon:hover{
    color: #ffffff !important;
}

.lg-download.lg-icon{
    display: none !important;
}

.lg-backdrop{
    background-color: #0000004d !important;
    -webkit-backdrop-filter: blur(5px) !important;
            backdrop-filter: blur(5px) !important;
}

.lg-prev,
.lg-next{
    width: 46px !important;
    height: 46px !important;
    color: var(--electricblue) !important;
    border: 2px solid var(--electricblue) !important;
    -webkit-border-radius: 50px !important;
       -moz-border-radius: 50px !important;
            border-radius: 50px !important;
    background-color: transparent !important;
    -webkit-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important;
    -moz-transition: all 0.3s ease-in-out !important;
    transition: all 0.3s ease-in-out !important;
}

.lg-prev:hover,
.lg-next:hover{
    color: white !important;
    background-color: var(--electricblue) !important;
}

.lg-prev:after {
    position: relative;
    top: -1px;
}

.lg-next:after {
    position: relative;
    top: -1px;
}

.lg-img-wrap{
    padding: 20px !important;
    padding-top: 0 !important;
}
/* --- 
Lightgallery end 
--- */


/* ---
Google Translate Start
--- */
.GT-main-bx{
    margin-left: 10px;
    position: relative;
    z-index: 11;
    cursor: pointer;
}

.GT-main-bx .GT-icon-bx{
    width: 32px;
    height: 32px;
}

.GT-main-bx .GT-icon-bx img{
    width: 100%;
    height: 100%;
}

.GT-main-bx .GT-drop-bx{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    padding-top: 50px;
    visibility: hidden;
}

.GT-main-bx.active .GT-drop-bx{
    visibility: visible;
}

.GT-drop-bx .GT-drop-inner{
    padding: 10px;
    -webkit-border-radius: 12px;
       -moz-border-radius: 12px;
            border-radius: 12px;
    background-clip: padding-box;
    background-color: var(--darkblue);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(5px);
       -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
         -o-transform: translateY(5px);
            transform: translateY(5px);
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    -webkit-box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
       -moz-box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
            box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
}

.GT-main-bx.active .GT-drop-bx .GT-drop-inner{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.GT-main-bx .GT-drop-bx .GT-drop-inner .GT-di-lable{
    color: var(--celeste);
    font-size: 14px;
    font-weight: 500;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 3px;
}

.GT-main-bx .GT-drop-bx .GT-drop-inner .close-icon{
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
}


.google_translate_element{
    font-weight: 400;
    font-family: 'Montserrat';
    color: var(--white);
    position: relative;
}

.google_translate_element select{
    font-weight: 400;
    display: block;
    color: var(--white);
    border: 0;
    font-size: 16px;
    background-color: var(--darkblue);
    border: 1px solid var(--celeste-400);
    width: 200px;
    outline: none;
    padding: 8px 30px 8px 10px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: 'Montserrat';
    position: relative;
    z-index: 1;
    /* margin-bottom: -16px !important; */
}

.google_translate_element::after{
    content: " ";
    position: absolute;
    right: 9px;
    top: 17px;
    background-color: transparent;
    width: 10px;
    height: 10px;
    border: 2px solid var(--celeste-800);
    border-top: none;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    border-left: none;
    z-index: 1;
}

.google_translate_element:hover::after{
    border-color: var(--electricblue);
}

.google_translate_element select:hover{
    border-color: var(--electricblue);
}

#goog-gt-tt{
    display: none !important;
}

@media screen and (max-width:576px) {
    .GT-main-bx .GT-icon-bx {
        width: 30px;
        height: 30px;
    }
}



.lighttheme .google_translate_element select,
.lighttheme .GT-drop-bx .GT-drop-inner{
    background-color: var(--white);
}

.lighttheme .google_translate_element select,
.lighttheme .GT-drop-bx .GT-drop-inner .GT-di-lable{
    color: var(--darkblue);
}

.lighttheme .google_translate_element::after,
.lighttheme .google_translate_element select{
    border-color: var(--darkblue-700);
}

.lighttheme .google_translate_element:hover::after,
.lighttheme .google_translate_element select:hover{
    border-color: var(--darkblue);
}

.lighttheme .GT-main-bx .GT-drop-bx .GT-drop-inner .close-icon{
    color: var(--darkblue);
}

.lighttheme .close-icon:hover{
    background-color: var(--darkblue-100);
}

/* ---
Google Translate End
--- */


/* ---
Flip Book Start
--- */
.flipbook-popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1111;
    overflow: hidden;
    overflow-y: auto;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px;
    display: flex;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.95);
    transition: all 0.3s ease-in-out
}

.flipbook-popup.active{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}


.flipbook-popup::-webkit-scrollbar{
    display: none;
}

.flipbook-popup .close-icon{
    z-index: 3;
    color: black;
    background-color: #f7f7f7;
}

.flipbook-popup .fliobook-overlayer{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 1;
}

.flipbook-item img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}




.fbb-items{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid var(--electricblue);
    background-color: var(--electricblue-100);
    padding: 5px;
    cursor: pointer;
    z-index: 3;
}

.fbb-items.fbb-prev{
    left: 10px;
}

.fbb-items.fbb-next{
    right: 10px;
}

.fbb-items img{
    width: 100%;
    height: 100%;
}

.fbb-items.fbb-prev img{
    transform: rotate(180deg);
}




.stf__parent{
    height: fit-content !important;
    margin: auto !important;
    z-index: 2;
}

.stf__wrapper.--landscape,
.stf__wrapper.--portrait{
    padding-bottom: 0 !important;
}
/* ---
Flip Book End
--- */

/* ---
Page 404 css start
--- */

.web-status-bx{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    min-height: 100vh;
    height: 100%;
    padding: 15px;
}

.web-status-bx .web-img{
    width: 280px;
    height: 280px;
    margin: 0 auto;
    display: block;
    object-fit: contain;
    object-position: bottom;
}

.web-status-bx .web-number{
    font-size: 150px;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    color: var(--celeste);
    text-shadow: 0 20px 20px #00000066;
    margin-bottom: -24px;
}

.web-status-bx .web-heading{
    font-size: 24px;
    color: var(--celeste);
    font-weight: 600;
}

.web-status-bx .web-text{
    color: var(--celeste-600);
    font-size: 16px;
    text-align: center;
    max-width: 420px;
    width: 100%;
}

@media screen and (max-width:576px) {

    .web-status-bx .web-text{
        font-size: 14px;
    }
}

@media screen and (max-width:420px) {
    .web-status-bx .web-number{
        font-size: 120px;
    }
}

/* ---
Page 404 css End
--- */

/* ---
Page thank you css start
--- */
.web-thankyou-bx{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 17px;
    min-height: 100vh;
    height: 100%;
    padding: 15px;

}

.web-thankyou-bx .web-img{
    width: 100px;
    height: 100px;
    transform: rotate(10deg);
}

.web-thankyou-bx .web-heading{
    font-size: 70px;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    color: var(--celeste);
    text-shadow: 0 20px 20px #00000066;
    margin-bottom: -15px;
}

.web-thankyou-bx .web-text{
    color: var(--celeste-600);
    font-size: 16px;
    text-align: center;
    max-width: 312px;
    width: 100%;
}

@media screen and (max-width:576px) {
    .web-thankyou-bx .web-heading{
        font-size: 52px;
    }

    .web-thankyou-bx .web-text{
        font-size: 14px;
    }
}

@media screen and (max-width:360px) {
    .web-thankyou-bx .web-heading{
        font-size: 48px;
    }
}

@media screen and (max-width:320px) {
    .web-thankyou-bx .web-heading{
        font-size: 42px;
    }
}

/* ---
Page thank you css End
--- */


/* --- Empty Box Css start --- */
.empty-div-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    gap: 10px;
    min-height: 190px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    padding: 30px 10px;
}

.empty-div-bx .empty-img {
    width: 70px;
    display: block;
    -webkit-filter: invert(1);
            filter: invert(1);
}

.empty-div-bx .empty-heading {
    line-height: normal;
    color: var(--celeste);
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-sub-heading {
    font-weight: 400;
    color: var(--celeste-600);
    font-size: 14px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .common-btn-item {
    margin-top: 5px;
}

@media screen and (max-width:576px) {
    .empty-div-bx .empty-heading {
        font-size: 16px;
    }
    .empty-div-bx .empty-sub-heading {
        font-size: 12px;
    }
}
/* --- Empty Box Css End--- */


/* --- common table start --- */
.table-responsive{
    overflow: hidden;
    overflow-x: auto;
}

.common-table{
    width: 100%;
    border-collapse: collapse;
}

.common-table thead tr th{
    font-size: 14px;
    padding: 10px 10px;
    border-bottom: 1px solid var(--celeste-600);
}

.common-table tbody tr{
    position: relative;
}

.common-table tbody tr td{
    white-space: nowrap;
    font-size: 16px;
    padding: 15px 10px;
    border-bottom: 1px solid var(--celeste-100);
}

.common-table tbody tr.tr-date{
    height: 54px;
    cursor: pointer;
}

.common-table tbody tr.tr-date .tr-data-bx{
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    width: 100%;
    padding: 15px 0px;
    color: var(--celeste-600);
    border-bottom: 1px solid var(--celeste-100);
    transition: all 0.3s ease-in-out;
}

.common-table tbody tr.tr-date:hover .tr-data-bx{
    color: var(--celeste);
    border-color: var(--celeste);
}

.common-table tbody tr.tr-date.active .tr-data-bx{
    color: var(--electricblue);
    border-color: var(--electricblue);
}

.common-table tbody tr.tr-date .tr-data-bx > div{
    position: sticky;
    left: 10px;
}

.common-table tbody tr.tr-date .tr-data-bx svg{
    position: sticky;
    right: 10px;
    width: 20px;
    height: 20px;
}

.common-table tbody tr .afp-data-bx{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: none;
    align-items: center;
}

.common-table tbody tr .afp-data-bx .afp-item{
    position: relative;
    padding: 10px 20px;
    font-size: 12px;
    color: var(--celeste-600);
}

.common-table tbody tr .afp-data-bx .afp-item:first-child{
    padding-left: 10px;
}

.common-table tbody tr .afp-data-bx .afp-item:last-child{
    padding-right: 10px;
}

.common-table tbody tr .afp-data-bx .afp-item::before{
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 1px;
    height: calc(100% - 20px);
    background-color: var(--celeste-100);
}

.common-table tbody tr .afp-data-bx .afp-item:first-child::before{
    content: none;
}

.common-table tbody tr .afp-data-bx .afp-item span{
    display: block;
    font-size: 16px;
    margin-top: 3px;
    color: var(--celeste);
}

@media screen and (max-width:768px) {

    .ec-table.common-table thead tr th:nth-child(6),
    .ec-table.common-table thead tr th:nth-child(5),
    .ec-table.common-table thead tr th:nth-child(4),
    .ec-table.common-table tbody tr td:nth-child(6),
    .ec-table.common-table tbody tr td:nth-child(5),
    .ec-table.common-table tbody tr td:nth-child(4){
        display: none;
    }
    
    .ec-table.common-table tbody tr td{
        padding-bottom: 70px;
        font-size: 14px;
    }

    .ec-table.common-table tbody tr .afp-data-bx{
        display: flex;
    }
}
/* --- common table end --- */


/* --- Blog item css start --- */
.blog-item{
    display: block;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(210 229 241 / 4%);
    border: 1px solid var(--celeste-100);
    transition: all 0.3s ease-in-out;
    height: 100%;
}

.blog-item .bi-img{
    position: relative;
    width: 100%;
    padding-top: 56%;
    border-radius: 10px;
    overflow: hidden;
}

.blog-item .bi-img img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease-in-out;
}

.blog-item .bi-heading{
    font-size: 16px;
    font-weight: 500;
    color: var(--celeste);
    margin-top: 5px;
    display: -webkit-inline-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: -8px;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
}

.blog-item:hover{
    border-color: var(--electricblue-400);
    background-color: var(--electricblue-100);
}

.blog-item:hover .bi-img img{
    transform: scale(1.05);
}

.blog-item:hover .bi-heading{
    color: var(--white);
}
/* --- Blog item css end --- */

/* --- Blog detail css start --- */
.blog-main-img{
    max-width: 768px;
    width: 100%;
    margin: auto;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    margin-top: -20px;
}

.blog-main-img img{
    width: 100%;
}

/* --- Lagal pages css start --- */
.blog-detail-bx{
    margin-top: 30px;
}

.blog-detail-bx div .main-lable {
    font-weight: 600;
    margin-top: 30px;
    color: var(--celeste);
    font-size: 20px;
    text-transform: capitalize;
}

.blog-detail-bx .main-sub-lable {
    margin-top: 30px;
    font-weight: 500;
    color: var(--celeste);
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.blog-detail-bx ul li,
.blog-detail-bx p {
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
    margin-top: 10px;
    color: var(--celeste-600);
    font-weight: 300;
    text-align: justify;
}

.blog-detail-bx ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin-left: 20px;
    margin-top: 10px;
}

.blog-detail-bx ul.ul-dick {
    list-style: disc;
}

.blog-detail-bx ul.ul-alpha {
    list-style: lower-alpha;
}

.blog-detail-bx ul.ul-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.blog-detail-bx ul.ul-flex li {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding-right: 30px;
    text-align: left;
}

@media screen and (max-width:992px) {
    .blog-detail-bx ul.ul-flex li {
        width: 33.33%;
    }

    .blog-heading{
        font-size: 28px;
    }
}

@media screen and (max-width:768px) {
    .blog-detail-bx ul.ul-flex li {
        width: 50%;
    }
    .blog-heading{
        font-size: 24px;
    }
}

@media screen and (max-width:450px) {
    .blog-detail-bx ul.ul-flex li {
        width: 100%;
        padding-right: 0px;
    }
}


.blog-detail-bx ul strong,
.blog-detail-bx p strong {
    color: var(--celeste);
}

.blog-detail-bx ul li a,
.blog-detail-bx p a {
    text-decoration: underline;
    color: var(--celeste);
}

.blog-detail-bx ul a:hover strong,
.blog-detail-bx p a:hover strong,
.blog-detail-bx ul a:hover,
.blog-detail-bx p a:hover {
    color: var(--electricblue);
}

/* --- Blog detail css end --- */


/* --- Site map page css start --- */
.sitemap-heading{
    position: relative;
    color: var(--celeste);
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 20px;
}

.sitemap-heading::before,
.sitemap-heading::after{
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 20px;
    height: 1px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    background-color:  var(--celeste-200);
}

.sitemap-heading::before{
    left: inherit;
    right: 0;
    top: 100%;
    width: -webkit-calc(100% - 30px);
    width: -moz-calc(100% - 30px);
    width: calc(100% - 30px);
}

.sitemap-links-ul{
    list-style: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;
    margin: 0 -10px;
}

.sitemap-links-ul .slu-li{
    flex: 0 0 25%;
    padding: 0 10px;
}

.sitemap-links-ul .slu-li .slul-link{
    display: flex;
    gap: 5px;
    position: relative;
    color: var(--celeste-600);
    font-size: 16px;
    font-weight: 400;
    margin-right: 0;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.sitemap-links-ul .slu-li .slul-link:hover {
    color: var(--celeste);
}

.sitemap-links-ul .slu-li .slul-link.active {
    color: var(--celeste);
}

.sitemap-links-ul .slu-li .slul-link svg{
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
    position: relative;
    top: 3.5px;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.sitemap-links-ul .slu-li .slul-link:hover svg{
    color: var(--electricblue);
}

@media screen and (max-width:1100px) {
    .sitemap-links-ul .slu-li{
        flex: 0 0 33.33%;
    }
}

@media screen and (max-width:900px) {
    .sitemap-links-ul .slu-li .slul-link{
        font-size: 14px;
    }
}

@media screen and (max-width:768px) {
    .sitemap-links-ul .slu-li{
        flex: 0 0 50%;
    }
}

@media screen and (max-width:360px) {
    .sitemap-links-ul .slu-li{
        flex: 0 0 100%;
    }
}

/* --- Site map page css end --- */



/* --- --- */
.award-slider{
    max-width: 100%;
    margin: auto;
}

.award-slider .award-item-outer{
    padding: 0 10px;
}

.award-slider .award-item-outer .award-section{
    width: 100%;
    max-width: 100%;
}

.award-section{
    display: flex;
    max-width: 800px;
    width: 100%;
    margin: auto;
    border-radius: 32px;
    background-color: var(--celeste-200);
}

.award-section .as-left{
    flex-basis: 280px;
    flex-shrink: 0;
}

.award-section .as-left .as-l-img{
    position: relative;
    background-color: var(--darkblue-700);
    height: 100%;
    padding: 30px;
}

.award-section .as-left .as-l-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    margin: auto;
    display: block;
}

.award-section .as-left svg.asl-svg-vert{
    position: absolute;
    top: -1px;
    left: 100%;
    fill: rgb(25 35 45);
    width: 50px;
    height: calc(100% + 1px);
    transform: rotatex(-180deg);
}

.award-section .as-left svg.asl-svg-hori{
    display: none;
    position: absolute;
    left: -1px;
    top: 100%;
    fill: rgb(25 35 45);
    width: calc(100% + 1px);
    height: 25px;
    transform: rotatey(-180deg);
}



.award-section .as-right{
    padding: 30px;
    padding-left: 70px;
    flex-basis: 100%;
}

.award-item{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.award-item .ai-img{
    position: relative;
    width: 210px;
    flex-basis: 210px;
    flex-shrink: 0;
}

.award-item .ai-img img{
    width: 100%;
}

.award-item .ai-data .ai-d-heading{
    color: var(--celeste);
    font-size: 24px;
    font-weight: 600;
}

.award-item .ai-data .ai-d-year{
    color: var(--electricblue);
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
}

@media screen and (max-width:1100px) {
    .award-section .as-left{
        flex-basis: 250px;
    }

    .award-item .ai-img{
        width: 180px;
        flex-basis: 180px;
    }    
}

@media screen and (max-width:800px) {
    .award-section .as-left{
        flex-basis: 220px;
    }

    .award-item .ai-data .ai-d-heading{
        font-size: 20px;
    }

    .award-item .ai-data .ai-d-year{
        font-size: 16px;
    }

    .award-item .ai-img{
        max-width: 160px;
        flex-basis: 160px;
    }
}

@media screen and (max-width:767px) {
    .award-section{
        flex-direction: column;
    }

    .award-section .as-left{
        flex: inherit;
        height: 250px;
    }

    .award-section .as-left svg.asl-svg-vert{
        display: none;
    }
    
    .award-section .as-left svg.asl-svg-hori{
        display: block;
    }

    .award-section .as-right{
        padding: 30px;
        padding-top: 50px;
    }
}

@media screen and (max-width:576px) {
    .award-section .as-left{
        height: 180px;
    }

    .award-section .as-right{
        padding: 50px 15px 30px;
    }

    .award-item{
        gap: 5px;
    }

    .award-item .ai-img{
        max-width: 120px;
        flex-basis: 120px;
    }

    .award-item .ai-data .ai-d-heading{
        font-size: 16px;
    }

    .award-item .ai-data .ai-d-year{
        font-size: 12px;
    }
}
/* --- --- */